import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'

function Header() {
    const profileInfo = useSelector(state => state.profile)
    const { authenticated, projectRole } = profileInfo
    const pName = (profileInfo && projectRole) ? projectRole.projectName : undefined
    const pRole = (profileInfo && projectRole) ? projectRole.role : undefined

    return (
        <header>
            <Navbar className="navbar navbar-dark  navbar-expand-xl bg-primary" style={{ height: "46.5px" }} fixed="top" >
                <Nav className="w-100">
                    <Nav.Link href="/">Mon projet {pName && `(${pName})`}</Nav.Link>
                    <Nav.Link href="/projects/">Changer de projet</Nav.Link>
                    <Nav.Link href="/account/">Mon compte</Nav.Link>
                    {
                        (pRole && (pRole > 0)) ?
                            <NavDropdown title="Traitement" id="nav-dropdown">
                                <NavDropdown.Item eventKey="0.0" href="/tasks/">Statut des tâches</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey="1.0.0" href="/export/">Export</NavDropdown.Item>
                                {
                                    (pRole && (pRole > 1)) && <>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="1.0" href="/fusion/">Fusion</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="1.2" href="/filtre/">Filtre</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="1.1" href="/import/yatea/">Import YaTea </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="2.1" href="/import/termsuite/">Import TermSuite</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="2.1" href="/import/skos/">Import SKOS</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="3.1" href="/import/listeplate/">Import Liste plate</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item eventKey="4.0" href="/import/docs/">Import documents</NavDropdown.Item>
                                    </>
                                }

                            </NavDropdown> : <></>
                    }

                    {
                        authenticated
                            ? <Nav.Link className="ml-auto " href="/logout/">Déconnexion</Nav.Link>
                            : <Nav.Link className="ml-auto " href="/account/">Connexion</Nav.Link>
                    }
                </Nav>
            </Navbar>
        </header>
    )
}

export default Header
