import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import NTree from "./NTree"
import { appStateAction } from './../actions/appStateActions';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { selectedSCAction } from '../actions/selectedSCActions';
import { isObjectEmpty } from '../utils/utils';


function NTreeItem({ item, hyponymsItem, related, associated, keyword, childParentSemanticCallback }) {
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()
    const myAppState = useSelector(state => state.appState)
    const selectedSC = useSelector(state => state.selectedSC)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    const onClickButton = () => {
        setOpen(open => !open)
        fetchTerm(item.termVedette.id)
        fetchSC(item.id)
    }

    const onClickTermRelation = (id) => {
        fetchTerm(id)
    }

    async function onClickRelatedClass(id) {
        await axios.get(`/p/${pId}/sc/item/${id}/`).then((res) => {
            fetchTerm(res.data.data.termVedette.id)
        })
    }

    async function fetchTerm(id) {
        const { data } = await axios.get(`/p/${pId}/terms/${id}`)
        dispatch(appStateAction({ ...myAppState, form: data.data, contextTab: true, detailsTab: true }))
    }

    async function fetchSC(id) {
        await axios.get(`/p/${pId}/sc/item/${id}`)
            .then(res => {
                dispatch(selectedSCAction(res.data.data))
                childParentSemanticCallback(res.data.data)
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }

    const getChildSCValue = (value) => {
        childParentSemanticCallback(value);
    };

    return <div>
        <Droppable droppableId={"1" + item.id.toString()} isDropDisabled={true}>
            {(provided) => (
                <div>
                    <div className='droppable-box'>
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Draggable
                                key={item.id} draggableId={`{"id": ${item.id}, "type": "sc", "hyper": ${item.hyper}}`} index={item.id}
                            >
                                {(provided) => (

                                    <div onClick={onClickButton} style={{ cursor: "pointer" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        {
                                            (item.has_hyponyms) && (!hyponymsItem ? (
                                                <i
                                                    className={!open ? "fas fa-caret-right mr-1" : "fas fa-caret-down mr-1"}
                                                />
                                            ) : <i
                                                className={"fas fa-caret-down mr-1"}
                                            />)
                                        }
                                        <span className={(keyword == item.termVedette.reference) ? `highlightSemantic` : (!isObjectEmpty(selectedSC) && item.termVedette.reference == selectedSC.termVedette.reference) ? "highlightSemanticClicked" : ""}>{item.termVedette.reference}</span>

                                    </div>

                                )}
                            </Draggable>
                        </div>
                    </div>
                </div>
            )}
        </Droppable>
        <Droppable droppableId={`{"itemId": ${item.id}, "type": "subClasse" }`} >
            {(provided, snapshot) => (
                <div className={`ml-3 droppable-box-mini ${snapshot.isDraggingOver ? "dragging" : ""}`} style={{ borderBottom: snapshot.isDraggingOver ? '1px solid rgb(32, 82, 12)' : '' }} {...provided.droppableProps} ref={provided.innerRef} id="box-mini"></div>
            )}
        </Droppable>

        {associated && Object.entries(item.associated).map(item => <div style={{ cursor: "pointer" }} onClick={() => onClickRelatedClass(item[0])}><i className="fas fa-link fa-xs" heigth="50%" /> {item[1]}</div>)}

        {related && <div>
            {(item.relations.VARIATE) && <div>{Object.entries(item.relations.VARIATE).map(i => <div style={{ cursor: "pointer" }} onClick={() => onClickTermRelation(i[0])}><b style={{ fontWeight: 800, fontSize: "12px" }}>V</b> {i[1]} </div>)}</div>}
            {(item.relations.ACRONYM) && <div>{Object.entries(item.relations.ACRONYM).map(i => <div style={{ cursor: "pointer" }} onClick={() => onClickTermRelation(i[0])}><b style={{ fontWeight: 800, fontSize: "12px" }}>A</b> {i[1]} </div>)}</div>}
            {(item.relations.TRANSLT) && <div>{Object.entries(item.relations.TRANSLT).map(i => <div style={{ cursor: "pointer" }} onClick={() => onClickTermRelation(i[0])}><i className="fas fa-language"></i> {i[1]} </div>)}</div>}
            {(item.relations.SYNONYM) && <div>{Object.entries(item.relations.SYNONYM).map(i => <div style={{ cursor: "pointer" }} onClick={() => onClickTermRelation(i[0])}><b style={{ fontWeight: 800, fontSize: "15px" }}>=</b> {i[1]} </div>)}</div>}
            {(item.relations["QUA-SYN"]) && <div>{Object.entries(item.relations["QUA-SYN"]).map(i => <div style={{ cursor: "pointer" }} onClick={() => onClickTermRelation(i[0])}><b style={{ fontWeight: 800, fontSize: "15px" }}>~</b> {i[1]} </div>)}</div>}
        </div>}
        {(!hyponymsItem) ? ((open) && (item.has_hyponyms) && <NTree childParentSemanticCallback={getChildSCValue} id={item.id} related={related} associated={associated} />) :
            (item.has_hyponyms) && <NTree childParentSemanticCallback={getChildSCValue} related={related} associated={associated} hyponyms={item.hyponyms} keyword={keyword} />
        }
    </div>
}
export default NTreeItem
