import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { getRole } from '../actions/profileActions'
import { isObjectEmpty } from '../utils/utils'
import { useNavigate } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });

function ProjectListScreen() {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [projectList, setProjectList] = useState([])
    const [loading, setLoading] = useState(false)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo


    useEffect(() => {
        setLoading(true)
        async function fetchProjects() {
            await axios.get('/p/list/')
                .then(res => {
                    setProjectList(res.data.data)
                    setLoading(false)
                })
                .catch(
                    function (error) {
                        setLoading(false)
                        return Promise.reject(error)
                    }
                )
        }
        fetchProjects()

    }, [])

    async function onClickButton(id, projectName) {
        if (isObjectEmpty(projectRole) || projectRole.projectId != id) await dispatch(getRole(id, projectName))
        navigate("/", { replace: true });
    }

    return (
        <div className="container-element ml-5 text-center" width="500px">

            {loading && <Loader />}
            <h3>Mes projets :</h3>
            <Grid
                item
                container
                direction="column"
                alignItems="center"
            >
                <ThemeProvider theme={lightTheme}>
                    <Box
                        sx={{
                            p: 2,
                            width: 500,
                            bgcolor: 'background.default',
                            display: 'grid',
                            gap: 2,
                        }}
                    >
                        {(projectList.length != 0) && projectList.map(item =>
                            <div>
                                <Item key={item.id} style={{ cursor: "pointer" }} onClick={() => onClickButton(item.id, item.label)} elevation={3}>
                                    {item.label}
                                </Item>
                            </div>
                        )}
                    </Box>
                </ThemeProvider>
            </Grid>

        </div>
    )
}

export default ProjectListScreen
