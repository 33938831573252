
import axios from 'axios'

import {
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    ROLE_REQUEST,
    ROLE_SUCCESS,
    ROLE_FAIL,
    ROLE_RESET,
    PROFILE_RESET,
} from '../constants/profileConstants'

export const getProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: PROFILE_REQUEST,
        })

        const { data } = await axios.get('/u/profile/')

        dispatch({
            type: PROFILE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PROFILE_FAIL,
            payload: error.response,
        })
    }
}


export const getRole = (id, projectName) => async (dispatch) => {
    try {
        dispatch({
            type: ROLE_REQUEST,
        })

        const { data } = await axios.get(`/p/${id}/perms/`)
        const res = await axios.get(`/p/${id}/`)

        dispatch({
            type: ROLE_SUCCESS,
            payload: { projectId: id, role: data.perm, projectName: projectName, reviews: res.data.data.reviews },
        })

        localStorage.setItem('projectRole', JSON.stringify({ projectId: id, role: data.perm, projectName: projectName, reviews: res.data.data.reviews }))


    } catch (error) {
        dispatch({
            type: ROLE_FAIL,
            payload: error.response,
        })
    }
}

export const logOut = () => (dispatch) => {
    localStorage.removeItem('projectRole')
    dispatch({ type: PROFILE_RESET })
}
