import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { Button, Card, Alert } from 'react-bootstrap';
import Loader from '../components/Loader';
import { labels } from '../config/config.json'
import { getCookie } from './../utils/utils';
import { useSelector } from 'react-redux';


function DocsImportScreen() {
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
        }, 2000)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    const maxSize = 6e+8;
    const { isDragActive, getRootProps, getInputProps, acceptedFiles, isDragReject, fileRejections } = useDropzone({
        multiple: true,
        accept: "text/plain",
        minSize: 0,
        maxSize
    });

    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response;
        } else {
            throw Error(response.statusText);
        }
    }

    const csrftoken = getCookie('csrftoken');

    async function getDataFromFile() {
        if (acceptedFiles[0] !== undefined) {
            setIsLoading(true)
            setMessage("")
            let data = new FormData()
            for (let i = 0; i < acceptedFiles.length; i++) {
                data.append('file_uploaded', acceptedFiles[i])
            }
            await fetch('/p/' + pId + '/upload/doc/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken
                },
                body: data
            })
                .then(checkError)
                .then(res =>
                    res.json()
                ).then(data => {
                    setIsLoading(false)
                    onShowSuccessMessage()
                    setMessage(`Fichiers uploadé avec succès`)
                })

                .catch((res) => {
                    setIsLoading(false)
                    onShowErrorMessage();
                    console.log(res.statusText);
                })
        }
    }

    const Onclickbutton = () => {
        getDataFromFile();
    }

    return (
        <div>

            <div className="container-element">

                <div className="container text-center mt-5">
                    <h4 className="mb-3">Import de documents</h4>
                    <p className="mb-3">{labels.documentsImport}</p>
                    <Alert className="mx-5" variant="success" show={showSuccess} >
                        Documents importés avec succès
                    </Alert>
                    <Alert className="mx-5" variant="danger" show={showError} >
                        Une erreur s'est produite lors de l'importation des documents
                    </Alert>

                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!isDragActive &&
                            <Card>
                                <Card.Body>Cliquez ici ou déposez un fichier pour l'importer !</Card.Body>
                            </Card>

                        }
                        {isDragActive && !isDragReject &&
                            <Card>
                                <Card.Body>Déposez votre fichier ici !</Card.Body>
                            </Card>
                        }
                        {isDragReject &&
                            <Card>
                                <Card.Body className="text-warning mt-2">Le type de fichier n'est pas accepté !</Card.Body>
                            </Card>
                        }
                        {isFileTooLarge && (
                            <div className="text-danger mt-2">Le fichier est trop volumineux !</div>
                        )}
                    </div>
                    {
                        isLoading && <div className="m-2"><Loader>Import des documents ...</Loader></div>
                    }

                    <p className="m-2">{message}</p>

                    <Button className="mt-2" onClick={Onclickbutton}>Import</Button>
                    <ul className="list-group mt-2">
                        {acceptedFiles.length > 0 && !isFileTooLarge && acceptedFiles.map(acceptedFile => (
                            <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                {acceptedFile.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default DocsImportScreen
