import React from "react";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiTablePagination from "@mui/material/TablePagination";
import { withStyles } from "@mui/styles";

import { rowsPerPageOptions } from "../config/config.json"

const defaultFooterStyles = {};

class CustomTableFooter extends React.Component {

    handleRowChange = event => {
        this.props.changeRowsPerPage(event.target.value);
    };

    handlePageChange = (_, page) => {
        this.props.changePage(page);
    };


    render() {
        const { count, classes, textLabels, rowsPerPage, page } = this.props;


        const footerStyle = {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px 24px 0px 24px',
            minHeight: "30px !important",
            maxHeight: "30px !important",
            height: "30px !important",

        };

        return (
            <TableFooter>
                <TableRow>
                    <TableCell style={footerStyle} colSpan={1000}>
                        <MuiTablePagination
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={textLabels.rowsPerPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
                            backIconButtonProps={{
                                'aria-label': textLabels.previous,
                            }}
                            nextIconButtonProps={{
                                'aria-label': textLabels.next,
                            }}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onPageChange={this.handlePageChange}
                            onRowsPerPageChange={this.handleRowChange}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }

}

export default withStyles(defaultFooterStyles, { name: "CustomTableFooter" })(CustomTableFooter);