import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { appStateReducer } from './reducers/AppStateReducers'
import { semanticRootReducer } from './reducers/semanticRootReducers';
import { selectedSCReducer } from './reducers/selectedSCReducers.js'
import { profileReducer } from './reducers/profileReducers';
import { treeStateReducer } from './reducers/treeStateReducers';


const reducer = combineReducers({
    appState: appStateReducer,
    semanticRoot: semanticRootReducer,
    selectedSC: selectedSCReducer,
    profile: profileReducer,
    treeState: treeStateReducer
})

const projectRoleFromStorage = localStorage.getItem('projectRole') ? JSON.parse(localStorage.getItem('projectRole')) : {}

const initialState = {
    appState: {
        forms: [],
        form: {},
        contextTab: false,
        detailsTab: false,
        addRelationIsOpen: false,
        AddTermIsOpen: false,
        addSCIsOpen: false,
        duplicateFormIsOpen: false,
        addSCTermIsOpen: false,
        metaTerms: {
            limit: 20,
            offset: 0,
            sort: [],
            postagRegex: '',
            surfaceRegex: ''
        }
    },
    semanticRoot: [],
    selectedSC: {},
    profile: {
        projectRole: projectRoleFromStorage
    }
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store