import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { getRole } from '../actions/profileActions'
import { getCookie, isObjectEmpty } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '@material-ui/core'
import PopUp from '../components/PopUp'
import DeleteIcon from '@mui/icons-material/Delete';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import EditIcon from '@mui/icons-material/Edit';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });


function AccountScreen() {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const profileInfo = useSelector(state => state.profile)
    const { profile, projectRole } = profileInfo
    const [projectList, setProjectList] = useState([])
    const [loading, setLoading] = useState(false)
    const [projectDetails, setProjectDetails] = useState()

    const pId = profileInfo ? projectRole.projectId : undefined

    const [validatedAddProject, setValidatedAddProject] = useState(false);
    const [validatedAddPerm, setValidatedAddPerm] = useState(false);
    const [validatedUpdateProject, setValidatedUpdateProject] = useState(false);
    const [validatedAddTag, setValidatedAddTag] = useState(false)

    const [checkedPerm, setCheckedPerm] = useState(false)

    const [projectLabel, setProjectLabel] = useState()
    const [dblind, setDblind] = useState(false)
    const [projectLabelAdd, setProjectLabelAdd] = useState()
    const [dblindAdd, setDblindAdd] = useState(false)
    const csrftoken = getCookie('csrftoken');

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [addPermIsOpen, setAddPermIsOpen] = useState(false)

    const [addProjectIsOpen, setaddProjectIsOpen] = useState(false)

    const [query, setQuery] = useState()
    const [usernameQuery, setUsernameQuery] = useState()
    const [profiles, setProfiles] = useState([])
    const [selectedProfile, setSelectedProfile] = useState()
    const [loadingProfile, setLoadingProfile] = useState(false)
    const [role, setRole] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [addTagIsOpen, setAddTagIsOpen] = useState(false)
    const [tag, setTag] = useState()
    const [description, setdescription] = useState()

    const [updateTagIsOpen, setUpdateTagIsOpen] = useState(false)
    const [updatePermIsOpen, setUpdatePermIsOpen] = useState(false)

    const [tagId, setTagId] = useState()
    const [permId, setPermId] = useState()
    const [permProfile, setPermProfile] = useState()
    const [permOrcid, setpermOrcid] = useState()
    const [permUsername, setPermUsername] = useState()

    useEffect(() => {
        setLoading(true)
        async function fetchProjects() {
            await axios.get('/p/list/')
                .then(res => {
                    setProjectList(res.data.data)
                    setLoading(false)
                })
                .catch(
                    function (error) {
                        setLoading(false)
                        return Promise.reject(error)
                    }
                )
        }
        fetchProjects()
    }, [refresh])

    useEffect(() => {
        async function fetchIsAdmin() {
            await axios.get('/p/0/perms/')
                .then(res => {
                    if (res.status == 200, res.data.perm === 3)
                        setIsAdmin(true)
                })
                .catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                )
        }
        fetchIsAdmin()
    }, [])

    useEffect(() => {
        async function fetchProfiles() {
            await axios.post(`/p/${projectDetails.id}/profiles/`, {
                'orcid_regex': query ? query : null,
                'username_regex': usernameQuery ? usernameQuery : null,
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
                .then(res => {
                    setProfiles(res.data)
                    setLoadingProfile(true)
                })
                .catch(
                    function (error) {
                        setLoadingProfile(true)
                        return Promise.reject(error)
                    }
                )
        }
        setLoadingProfile(false)
        if (query !== undefined || usernameQuery !== undefined) fetchProfiles()
    }, [query, usernameQuery])

    const handleInputChange = query => {
        setProfiles([])
        setQuery(query);
        setUsernameQuery(null)
    };

    const handleUsernameInputChange = query => {
        setProfiles([])
        setQuery(null);
        setUsernameQuery(query)
    };


    async function onClickButton(id, projectName) {
        if (isObjectEmpty(projectRole) || projectRole.projectId != id) await dispatch(getRole(id, projectName))
        navigate("/", { replace: true });
    }

    async function showProject(id) {
        const { data } = await axios.get(`/p/${id}/`)
        setProjectDetails(data.data)
        setProjectLabel(data.data.label)
        setDblind(data.data.dblind)
    }

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }

    const onShowSuccessAddPermMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            setAddPermIsOpen(false)
            setRole()
            setSelectedProfile()
            setValidatedAddPerm(false)
            setCheckedPerm(false)
            setUpdatePermIsOpen(false)
        }, 500)
    };

    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            setaddProjectIsOpen(false)
            setUpdateTagIsOpen(false)
            setAddTagIsOpen(false)
        }, 500)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    async function onClickUpdateButton() {
        if (projectLabel !== undefined && projectLabel !== "") {
            fetch(`/p/update/${projectDetails.id}/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    label: projectLabel,
                    dblind
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    setRefresh(!refresh)
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
        setValidatedUpdateProject(true);
    }

    async function onClickAddButton(e) {
        e.preventDefault()
        if (projectLabelAdd !== undefined && projectLabelAdd !== "") {
            fetch(`/p/add/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    label: projectLabelAdd,
                    dblind: dblindAdd
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    setRefresh(refresh => !refresh)
                    setValidatedAddProject(false)
                    setProjectLabelAdd()
                    setDblind()
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
        setValidatedAddProject(true);
    }

    async function onClickAddTagButton(e) {
        e.preventDefault()
        if (tag !== undefined && tag !== "") {
            fetch(`/p/${projectDetails.id}/reviews/add/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    tag,
                    description
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    showProject(projectDetails.id)
                    setValidatedAddTag(false)
                    setTag()
                    setdescription()
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
        setValidatedAddTag(true);
    }

    const handleEditTag = (id, tag, description) => {
        setAddTagIsOpen(true)
        setUpdateTagIsOpen(true)
        setTag(tag)
        setdescription(description)
        setTagId(id)
    }

    const handleEditPerm = (id, profile, orcid, role, username) => {
        setUpdatePermIsOpen(true)
        setAddPermIsOpen(true)
        setPermId(id)
        setPermProfile(profile)
        setpermOrcid(orcid)
        setRole(role)
        setPermUsername(username)
    }

    async function onClickUpdateTag(e) {
        e.preventDefault()

        if (tag !== undefined && tag !== "") {
            fetch(`/p/${projectDetails.id}/reviews/update/${tagId}/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    project: projectDetails.id,
                    tag,
                    description
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    showProject(projectDetails.id)
                    setValidatedAddTag(false)
                    setTag()
                    setdescription()
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
        setValidatedAddTag(true);
    }

    function onAddperm() {
        setAddPermIsOpen(addPermIsOpen => !addPermIsOpen)
    }

    function formHandleAddPerm(e) {
        e.preventDefault()
        setCheckedPerm(true);
        if (selectedProfile !== undefined && !isObjectEmpty(selectedProfile[0]) && role !== undefined && role !== null) {
            setValidatedAddPerm(true);
            setCheckedPerm(false);
            fetch(`/p/${projectDetails.id}/perms/add/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    profile: selectedProfile[0].id,
                    orcid: selectedProfile[0].orcid,
                    project: projectDetails.id,
                    role
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessAddPermMessage();
                    showProject(projectDetails.id)
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }


    }

    function formHandleUpdatePerm(e) {
        e.preventDefault()
        setCheckedPerm(true);
        if (role !== undefined && role !== null && role !== 0) {
            setValidatedAddPerm(true);
            setCheckedPerm(false);
            fetch(`/p/${projectDetails.id}/perms/update/${permId}/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify({
                    profile: permProfile,
                    orcid: permOrcid,
                    project: projectDetails.id,
                    role
                }),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessAddPermMessage();
                    showProject(projectDetails.id)
                    setRole()
                    setPermId()
                    setpermOrcid()
                    setPermProfile()
                    setPermUsername()
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
    }

    async function onDeleteProject(id) {
        await fetch(`/p/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        });
        setRefresh(refresh => !refresh)
        if (projectDetails && (id == projectDetails.id))
            setProjectDetails()

    }


    async function onDeletePerm(id) {
        await fetch(`/p/${projectDetails.id}/perms/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        });
        showProject(projectDetails.id)
    }

    async function onDeleteTag(id) {
        await fetch(`/p/${projectDetails.id}/reviews/delete/${id}/`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        });
        showProject(projectDetails.id)
    }



    return (
        <div className="container-element ml-5">
            {addPermIsOpen && <PopUp
                content={<>
                    <div>
                        <Alert className="mx-5" variant="success" show={showSuccess} >
                            {
                                updatePermIsOpen ? "Permission modifié avec succès" : "Permission ajouté avec succès"
                            }

                        </Alert>
                        <Alert className="mx-5" variant="danger" show={showError} >
                            {
                                updatePermIsOpen ? "Une erreur s'est produite lors de la modification de permission" : "Une erreur s'est produite lors de la création de permission"
                            }

                        </Alert>
                        <Form className="mt-3 mx-5" noValidate validated={validatedAddPerm} >
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    ORCID <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    {
                                        updatePermIsOpen
                                            ? <Form.Control defaultValue={permOrcid} disabled />
                                            : <>
                                                <Typeahead
                                                    className={checkedPerm && (selectedProfile == undefined || selectedProfile[0] == undefined) ? "is-invalid" : ""}
                                                    isInvalid={checkedPerm && (selectedProfile == undefined || selectedProfile[0] == undefined)}
                                                    selected={selectedProfile ? selectedProfile : []}
                                                    id="search-profile"
                                                    onChange={setSelectedProfile}
                                                    labelKey="orcid"
                                                    emptyLabel={!(query !== undefined && query !== null && query.length !== 0) ? "Commencer à saisir..." : !loadingProfile ? "Recherche..." : "Aucune correspondance trouvée"}
                                                    options={(query !== undefined && query !== null && query.length !== 0) ? profiles : []}
                                                    onInputChange={handleInputChange}
                                                    placeholder="Sélectionnez un profil..."
                                                    style={{ flex: 1 }}
                                                />
                                                <Form.Control.Feedback type="invalid">{(query && query.length > 0) ? "Veuillez renseigner une valeur existante." : "Veuillez renseigner ce champ."}</Form.Control.Feedback>
                                            </>
                                    }
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Nom d'utilisateur : <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    {
                                        updatePermIsOpen
                                            ? <Form.Control defaultValue={permUsername} disabled />
                                            : <>
                                                <Typeahead
                                                    className={checkedPerm && (selectedProfile == undefined || selectedProfile[0] == undefined) ? "is-invalid" : ""}
                                                    isInvalid={checkedPerm && (selectedProfile == undefined || selectedProfile[0] == undefined)}
                                                    selected={selectedProfile ? selectedProfile : []}
                                                    id="basic-profile-by-username"
                                                    onChange={setSelectedProfile}
                                                    labelKey="username"
                                                    emptyLabel={!(usernameQuery !== undefined && usernameQuery !== null && usernameQuery.length !== 0) ? "Commencer à saisir..." : !loadingProfile ? "Recherche..." : "Aucune correspondance trouvée"}
                                                    options={(usernameQuery !== undefined && usernameQuery !== null && usernameQuery.length !== 0) ? profiles : []}
                                                    onInputChange={handleUsernameInputChange}
                                                    placeholder="Sélectionnez un profil ..."
                                                    style={{ flex: 1 }}
                                                />
                                                <Form.Control.Feedback type="invalid">{(usernameQuery && usernameQuery.length > 0) ? "Veuillez renseigner une valeur existante." : "Veuillez renseigner ce champ."}</Form.Control.Feedback>
                                            </>
                                    }

                                </Col>
                            </Form.Group>
                            <Form.Group>
                                <div className="text-center mb-3">
                                    <Form.Control
                                        className={checkedPerm && (role == null) ? "is-invalid" : ""}
                                        isInvalid={checkedPerm && (role == null)}
                                        required
                                        as="select"
                                        size="sm"
                                        aria-label="select role"
                                        value={role}
                                        onChange={(e) => { if (e.target.value != 0) setRole(e.target.value); else setRole() }}
                                    >
                                        <option value={0}>Role... *</option>
                                        <option value={"GUEST"}>Visiteur</option>
                                        <option value={"EDITO"}>Editeur</option>
                                        <option value={"CHIEF"}>Chef de projet</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Veuillez renseigner ce champ.
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <div className="text-center">
                                {
                                    updatePermIsOpen ? <Button type="submit" onClick={formHandleUpdatePerm}>Modifier</Button> : <Button type="submit" onClick={formHandleAddPerm}>Ajouter</Button>
                                }
                            </div>

                        </Form>
                    </div>
                </>}
                handleClose={() => {
                    setAddPermIsOpen(addPermIsOpen => !addPermIsOpen)
                    setRole()
                    setPermId()
                    setpermOrcid()
                    setPermProfile()
                    setPermUsername()
                    setUpdatePermIsOpen(false)
                    setCheckedPerm(false)
                }}
            />}
            {addTagIsOpen && <PopUp
                content={<>
                    <div>
                        <Alert className="mx-5" variant="success" show={showSuccess} >
                            {
                                updateTagIsOpen ? 'Tag modifié avec succès' : 'Tag ajouté avec succès'
                            }
                        </Alert>
                        <Alert className="mx-5" variant="danger" show={showError} >
                            {
                                updateTagIsOpen ? "Une erreur s'est produite lors de la modification de tag" : "Une erreur s'est produite lors de la création de tag"
                            }
                        </Alert>
                        <Form className="mt-3 mx-5" noValidate validated={validatedAddTag} >
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Tag: <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    <Form.Control required defaultValue={tag} onChange={(e) => (setTag(e.target.value))} />
                                    <Form.Control.Feedback type="invalid">
                                        Veuillez renseigner ce champ.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Description:
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    <Form.Control as="textarea" defaultValue={description} onChange={(e) => (setdescription(e.target.value))} />
                                </Col>
                            </Form.Group>
                            <div className='text-center mt-2'>
                                {
                                    updateTagIsOpen ? <Button type="submit" onClick={onClickUpdateTag}>Modifier</Button> : <Button type="submit" onClick={onClickAddTagButton}>Ajouter</Button>
                                }
                            </div>
                        </Form>
                    </div>
                </>}
                handleClose={() => { setAddTagIsOpen(addTagIsOpen => !addTagIsOpen); setUpdateTagIsOpen(false); setTag(); setdescription(); setValidatedAddTag() }}
            />}
            {addProjectIsOpen && <PopUp
                content={<>
                    <div>
                        <Alert className="mx-5" variant="success" show={showSuccess} >
                            Projet ajouté avec succès
                        </Alert>
                        <Alert className="mx-5" variant="danger" show={showError} >
                            Une erreur s'est produite lors de la création du projet
                        </Alert>
                        <Form className="mt-3 mx-5" noValidate validated={validatedAddProject} >
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Libellé du projet: <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    <Form.Control required defaultValue={projectLabelAdd} onChange={(e) => (setProjectLabelAdd(e.target.value))} />
                                    <Form.Control.Feedback type="invalid">
                                        Veuillez renseigner ce champ.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Double aveugle:
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    <Checkbox
                                        checked={dblindAdd}
                                        onChange={() => setDblindAdd(dblindAdd => !dblindAdd)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="default"

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Veuillez renseigner ce champ.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <div className='text-center mt-2'>
                                <Button type="submit" onClick={onClickAddButton}>Ajouter le projet</Button>
                            </div>
                        </Form>
                    </div>
                </>}
                handleClose={() => setaddProjectIsOpen(addProjectIsOpen => !addProjectIsOpen)}
            />}
            <Row>
                <Col xl={2} lg={4}>
                    <h3>Mon profil</h3>
                    <Row><Col>Nom d'utilisateur:</Col><Col>{profile.username}</Col> </Row>
                    <Row>
                        <Col>Nom:</Col>
                        <Col>{profile.last_name}</Col>
                    </Row>
                    <Row>
                        <Col>Prénom:</Col>
                        <Col>{profile.first_name}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <a href={`https://orcid.org/${profile.orcid}`} class="d-flex align-items-center">
                                <img
                                    class="mr-1"
                                    alt="ORCID logo"
                                    src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_16x16.png"
                                    width="16"
                                    height="16"
                                />
                                {profile.orcid}
                            </a>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>Biography:</Col>
                        <Col>{profile.biography}</Col>
                    </Row> */}
                </Col>
                <Col xl={4} lg={7}>
                    <h3>Mes projets :</h3>
                    {loading && <Loader />}
                    <Grid item>
                        <ThemeProvider theme={lightTheme}>
                            <Box
                                sx={{
                                    p: 2,
                                    width: 500,
                                    bgcolor: 'background.default',
                                    display: 'grid',
                                    gap: 2,
                                }}
                            >
                                {(projectList.length != 0) && projectList.map((item) =>
                                    <div key={item.id}>
                                        <Item key={item.id} style={{ cursor: "pointer" }} onClick={() => onClickButton(item.id, item.label)} elevation={3}>
                                            {item.label}
                                        </Item>
                                        <div className='text-center mt-2'>
                                            {(item.permissions >= 2) &&
                                                <Button variant="info" className='mr-1' onClick={() => showProject(item.id)}>Details</Button>
                                            }
                                            {(isAdmin) &&
                                                <Button variant='danger' onClick={() => onDeleteProject(item.id)}>Supprimer</Button>
                                            }
                                        </div>
                                    </div>
                                )}

                                {(isAdmin) &&
                                    <div className='text-center mt-2'>
                                        <Button onClick={() => setaddProjectIsOpen(true)}>Ajouter un projet</Button>
                                    </div>
                                }
                            </Box>
                        </ThemeProvider>
                    </Grid>
                </Col>

                <Col className='ml-5' xl={5} lg={8}>
                    {
                        !!projectDetails && <div>
                            <h3>Paramétrage du projet :</h3>
                            <Alert className="mx-5" variant="success" show={showSuccess} >
                                Projet modifié avec succès
                            </Alert>
                            <Alert className="mx-5" variant="danger" show={showError} >
                                Une erreur s'est produite lors de la modification de projet
                            </Alert>
                            <Form className="mt-3" noValidate validated={validatedUpdateProject} >
                                <Form.Group as={Row} className="mb-3" controlId="">
                                    <Form.Label column sm="6" xs="12">
                                        Libellé du projet: <span style={{ color: "red" }}>*</span>
                                    </Form.Label>
                                    <Col sm="6" xs="12">
                                        <Form.Control required value={projectLabel} onChange={(e) => (setProjectLabel(e.target.value))} />
                                        <Form.Control.Feedback type="invalid">
                                            Veuillez renseigner ce champ.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="">
                                    <Form.Label column sm="6" xs="12">
                                        Double aveugle:
                                    </Form.Label>
                                    <Col sm="6" xs="12">
                                        <Checkbox
                                            checked={dblind}
                                            onChange={() => setDblind(dblind => !dblind)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color="default"
                                        // size="small"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Veuillez renseigner ce champ.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <div className='text-center mt-1'>
                                    <Button onClick={onClickUpdateButton}>Modifier le projet</Button>
                                </div>
                                <div className='mb-1 mt-2'>
                                    Permissions :
                                </div>

                                <Table striped bordered hover responsive className='table-sm'>
                                    <thead>
                                        <tr>
                                            <th>Nom d'utilisateur</th>
                                            <th>ORCID</th>
                                            <th>Role</th>
                                            <th style={{ width: "17%" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectDetails.permissions.map(perm => (
                                            <tr key={perm.id}>
                                                <td>{perm.username}</td>

                                                <td>{perm.orcid}</td>
                                                <td>
                                                    {(perm.role == "GUEST") && "Visiteur"}
                                                    {(perm.role == "EDITO") && "Editeur"}
                                                    {(perm.role == "CHIEF") && "Chef de projet"}
                                                </td>
                                                <td>
                                                    <Button size="sm" variant='danger' onClick={() => onDeletePerm(perm.id)}><DeleteIcon /></Button>
                                                    <Button size="sm" className="ml-1" variant='warning' onClick={() => handleEditPerm(perm.id, perm.profile, perm.orcid, perm.role, perm.username)}><EditIcon /></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='text-center mt-1'>
                                    <Button onClick={onAddperm}>Ajouter une permission</Button>
                                </div>
                                <div className='mb-1 mt-2'>
                                    Description des avis :
                                </div>

                                <Table striped bordered hover className='table-sm'>
                                    <thead>
                                        <tr>
                                            <th>Tag</th>
                                            <th>Description</th>
                                            <th style={{ width: "17%" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectDetails.reviews.map(review => (
                                            <tr key={review.id}>
                                                <td>{review.tag}</td>
                                                <td>{review.description}</td>
                                                <td>
                                                    <Button size="sm" variant='danger' onClick={() => onDeleteTag(review.id)}><DeleteIcon /></Button>
                                                    <Button size="sm" className="ml-1" variant='warning' onClick={() => handleEditTag(review.id, review.tag, review.description)}><EditIcon /></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='text-center mt-2'>
                                    <Button onClick={() => setAddTagIsOpen(!addTagIsOpen)}>Ajouter un tag</Button>
                                </div>
                            </Form>

                        </div>
                    }
                </Col>


            </Row>
        </div>
    )
}

export default AccountScreen
