import React, { useEffect, useState } from 'react'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import axios from 'axios'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDispatch, useSelector } from 'react-redux'
import { semanticRootAction } from './../actions/semanticRootActions';
import { getCookie, isObjectEmpty } from '../utils/utils';
import { appStateAction } from '../actions/appStateActions';

function AddSC({ semantic, setAddSCIsClose }) {
    const myAppState = useSelector(state => state.appState)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    let term
    if (myAppState.addSCTermIsOpen === true) {
        term = (myAppState.form === null || myAppState.form === undefined) ? '' : [myAppState.form]
    } else if (semantic != undefined) {
        term = [semantic.termVedette]
    }
    const dispatch = useDispatch()
    const mySCAppState = useSelector(state => state.semanticRoot)
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [termVedette, setTermVedette] = useState(term)
    const [hypernymSC, setHypernymSC] = useState([])
    const [associatedSC, setAssociatedSC] = useState([])

    const [terms, setTerms] = useState([])
    const [termsHsc, setTermsHsc] = useState([])
    const [termsAsc, setTermsAsc] = useState([])

    const [query, setQuery] = useState({
        'surface_regex': "",
        'sort': (["reference"]),
        "is_vedette": false
    })

    const [queryAsc, setQueryAsc] = useState({
        'surface_regex': "",
        'sort': (["reference"]),
        "is_vedette": true
    })

    const [queryHsc, setQueryHsc] = useState({
        'surface_regex': "",
        'sort': (["reference"]),
        "is_vedette": true
    })
    const [loading, setLoading] = useState(false)

    const [semantic_hypernyms, setSemantic_hypernyms] = useState();
    const [semantic_associated, setSemantic_associated] = useState();

    const [validated, setValidated] = useState(false);
    const [checked, setChecked] = useState(false)

    function fetchSCs(updateVariable, type) {
        let promises
        if (type == "hyper") {
            promises = semantic.hypernyms.map(id => {
                return fetch(`/p/${pId}/sc/${id}`)
                    .then(response => {
                        return response.json()
                    });
            });
        }
        else if (type == "assoc") {
            promises = Object.entries(semantic.associated).map(id => {
                return fetch(`/p/${pId}/sc/${id[0]}`)
                    .then(response => {
                        return response.json()
                    });
            });
        }

        Promise.all(promises).then(results => {
            const data = results.map(result => result.data.termVedette);
            updateVariable(data);
        })
    }

    const tVHandleInputChange = query => {
        setTerms([])
        setQuery({
            'surface_regex': query ? `(^${query})` : "",
            'sort': (["reference"]),
            "is_vedette": false
        });
    };

    const sHCHandleInputChange = query => {
        setTermsHsc([])
        setQueryHsc({
            'surface_regex': query ? `(^${query})` : "",
            'sort': (["reference"]),
            "is_vedette": true,
        });
    };

    const sACHandleInputChange = query => {
        setTermsAsc([])
        setQueryAsc({
            'surface_regex': query ? `(^${query})` : "",
            'sort': (["reference"]),
            "is_vedette": true,
        });
    };

    const csrftoken = getCookie('csrftoken');

    useEffect(() => {
        if (semantic != undefined && semantic.hypernyms && isObjectEmpty(hypernymSC) && (semantic_hypernyms == undefined)) {
            fetchSCs(setSemantic_hypernyms, "hyper")
        }
        if (semantic != undefined && semantic.associated && isObjectEmpty(associatedSC) && (semantic_associated == undefined)) {
            fetchSCs(setSemantic_associated, "assoc")
        }
    }, [])


    useEffect(() => {
        setLoading(false)
        if (query !== undefined && query.length !== 0) fetchTerms(query, setTerms)
    }, [query])

    useEffect(() => {
        setLoading(false)
        if (queryHsc !== undefined && queryHsc.length !== 0) fetchTerms(queryHsc, setTermsHsc)
    }, [queryHsc])

    useEffect(() => {
        setLoading(false)
        if (queryAsc !== undefined && queryAsc.length !== 0) fetchTerms(queryAsc, setTermsAsc)
    }, [queryAsc])

    async function fetchTerms(query, setTerms, limit = 5, offset = 0) {
        await axios.post(`/p/${pId}/terms/?limit=${limit}&offset=${offset}`, query, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            }
        })
            .then(res => {
                setTerms(res.data.data)
                setLoading(true)
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }

    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            toggleAddSCTermPopup()
            toggleAddSCPopup()
            if (setAddSCIsClose) setAddSCIsClose(false)
        }, 500)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };


    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }

    async function fetchSC() {
        await axios.get(`/p/${pId}/sc/root?offset=0&limit=100`)
            .then(res => {
                dispatch(semanticRootAction({ ...mySCAppState, semanticRoot: res.data.data }))
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }

    const formHandleSubmit = event => {
        event.preventDefault();
        const sC = {
            termVedette: (termVedette !== undefined && termVedette[0] !== undefined) ? termVedette[0].id : null,
            hypernymSC: hypernymSC.map((i) => i.vedette),
            associatedSC: associatedSC.map((i) => i.vedette),
            project: pId
        };
        setChecked(true);
        if (termVedette !== undefined && termVedette[0] !== undefined && queryAsc.surface_regex.length == 0 && queryHsc.surface_regex.length == 0) {
            setValidated(true);
            setChecked(false);
            fetch('/p/' + pId + '/sc/add/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify(sC),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    fetchSC();
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }

        if (termVedette !== undefined && termVedette[0] !== undefined && queryAsc.surface_regex.length == 0 && queryHsc.surface_regex.length == 0) {
            setTermVedette()
            setAssociatedSC([])
            setHypernymSC([])
        }
    }

    const formHandleUpdate = event => {
        event.preventDefault();
        const sC = {
            termVedette: (termVedette !== undefined && termVedette[0] !== undefined) ? termVedette[0].id : null,
            hypernymSC: (hypernymSC.length != 0) ? hypernymSC.map((i) => i.vedette) : (semantic_hypernyms) ? semantic_hypernyms.map((i) => i.vedette) : [],
            associatedSC: (associatedSC.length != 0) ? associatedSC.map((i) => i.vedette) : semantic_associated.map((i) => i.vedette),
            project: pId
        };
        setChecked(true);
        if (termVedette !== undefined && termVedette[0] !== undefined && queryAsc.surface_regex.length == 0 && queryHsc.surface_regex.length == 0) {
            setValidated(true);
            setChecked(false);
            fetch(`/p/${pId}/sc/update/${semantic.id}/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify(sC),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    fetchSC();
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
    }

    const onChangeAssociatedSC = (data) => {
        setAssociatedSC(data)
        if (semantic != undefined) setSemantic_associated(data)
    }

    const onChangeHypernymSC = (data) => {
        setHypernymSC(data)
        if (semantic != undefined) setSemantic_hypernyms(data)
    }

    const handleDelete = (id) => {
        setAssociatedSC(associatedSC.filter((item) => item.id !== id))
    }
    const hypernymSCHandleDelete = (id) => {
        setHypernymSC(hypernymSC.filter((item) => item.id !== id))
    }

    const toggleAddSCPopup = () => {
        dispatch(appStateAction({ ...myAppState, addSCIsOpen: false, refresh: true }))
    }

    const toggleAddSCTermPopup = () => {
        dispatch(appStateAction({ ...myAppState, addSCTermIsOpen: false, refresh: true }))
    }


    return (
        <div>
            <Alert className="mx-5" variant="success" show={showSuccess} >
                Classe sémantique ajouté avec succès
            </Alert>
            <Alert className="mx-5" variant="danger" show={showError} >
                Une erreur s'est produite lors de la création classe sémantique
            </Alert>
            <Form className="mt-3 mx-5" noValidate validated={validated} >
                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Terme vedette <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            className={checked && (termVedette == undefined || termVedette[0] == undefined) ? "is-invalid" : ""}
                            isInvalid={checked && (termVedette == undefined || termVedette[0] == undefined)}
                            id="search-term"
                            onChange={(data) => { setTermVedette(data); setTerms([]) }}
                            labelKey="reference"
                            emptyLabel={!(query !== undefined && query.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(query !== undefined && query.length !== 0) ? terms : []}
                            onInputChange={tVHandleInputChange}
                            placeholder="Sélectionnez un terme..."
                            selected={termVedette || []}
                        />
                        <Form.Control.Feedback type="invalid">{(query && query.surface_regex.length > 0) ? "Veuillez renseigner une valeur existante." : "Veuillez renseigner ce champ."}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Hyperonyme
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            selected={isObjectEmpty(hypernymSC) && semantic_hypernyms != undefined ? semantic_hypernyms : hypernymSC}
                            isInvalid={checked && (queryHsc.surface_regex.length != 0)}
                            className={checked && (queryHsc.surface_regex.length != 0) ? "is-invalid" : ""}
                            id="search-class"
                            onChange={(data) => { onChangeHypernymSC(data); setTerms([]); setQueryHsc({ ...queryHsc, surface_regex: "" }) }}
                            labelKey="reference"
                            multiple
                            emptyLabel={!(queryHsc !== undefined && queryHsc.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(queryHsc !== undefined && queryHsc.length !== 0) ? termsHsc : []}
                            onInputChange={sHCHandleInputChange}
                            placeholder="Sélectionnez une classe..."
                            style={{ flex: 1 }}
                        />
                        <Form.Control.Feedback type="invalid"> Veuillez renseigner une valeur existante.</Form.Control.Feedback>
                    </Col>

                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Classes sémantiques associées
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            selected={isObjectEmpty(associatedSC) && semantic_associated != undefined ? semantic_associated : associatedSC}
                            isInvalid={checked && (queryAsc.surface_regex.length != 0)}
                            className={checked && (queryAsc.surface_regex.length != 0) ? "is-invalid" : ""}
                            id="basic-example"
                            onChange={(data) => { onChangeAssociatedSC(data); setTerms([]); setQueryAsc({ ...queryAsc, surface_regex: "" }) }}
                            labelKey="reference"
                            multiple
                            emptyLabel={!(queryAsc !== undefined && queryAsc.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(queryAsc !== undefined && queryAsc.length !== 0) ? termsAsc : []}
                            onInputChange={sACHandleInputChange}
                            placeholder="Sélectionnez une classe..."
                            style={{ flex: 1 }}
                        />
                        <Form.Control.Feedback type="invalid"> Veuillez renseigner une valeur existante.</Form.Control.Feedback>
                    </Col>

                </Form.Group>
                {(semantic == undefined) ? <div className="text-center"><Button type="submit" onClick={formHandleSubmit}>Ajouter la classe</Button></div> :
                    <div className="text-center"><Button type="submit" onClick={formHandleUpdate}>Modifier la classe</Button></div>
                }
            </Form>
        </div>
    )
}

export default AddSC