import {
    APP_STATE_SUCCESS
} from '../constants/appStateConstants'

export const appStateReducer = (state = {}, action) => {
    switch (action.type) {
        case APP_STATE_SUCCESS:
            return { ...action.payload }

        default:
            return state
    }

}
