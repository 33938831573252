import * as React from "react";
import { Container } from 'react-bootstrap'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header';
import AccountScreen from './screens/AccountScreen';
import YaTeaImportScreen from './screens/YaTeaImportScreen';
import TermSuiteImportScreen from "./screens/TermSuiteImportScreen";
import ListPlatImportScreen from "./screens/ListPlatImportScreen"
import FusionScreen from './screens/FusionScreen';
import DocsImportScreen from './screens/DocsImportScreen';
import FilterScreen from './screens/FilterScreen';
import SkosImportScreen from './screens/SkosImportScreen';
import ProjectScreen from "./screens/ProjectScreen";
import TasksStatusScreen from './screens/TasksStatusScreen';
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./screens/Unauthorized";
import NotFound from "./screens/NotFound";
import LogoutScreen from "./screens/LogoutScreen";
import ProjectListScreen from "./screens/ProjectListScreen";
import ExportScreen from './screens/ExportScreen';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Container className="p-0" fluid>
        <Routes>
          <Route element={<RequireAuth projectLessRoute={true} allowedRoles={[0, 1, 2, 3, 4]} />}>
            <Route path='/projects/' element={<ProjectListScreen />} />
            <Route path='/account/' element={<AccountScreen />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[0, 1, 2, 3, 4]} />}>
            <Route path='/' element={<ProjectScreen />} exact />
          </Route>

          <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4]} />}>
            <Route path='/export/' element={<ExportScreen />} exact />
            <Route path='/tasks/' element={<TasksStatusScreen />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[2, 3, 4]} />}>
            <Route path='/import/yatea' element={<YaTeaImportScreen />} />
            <Route path='/import/termsuite' element={<TermSuiteImportScreen />} />
            <Route path='/import/listeplate' element={<ListPlatImportScreen />} />
            <Route path='/import/skos' element={<SkosImportScreen />} />
            <Route path='/import/docs/' element={<DocsImportScreen />} />
            <Route path='/fusion' element={<FusionScreen />} />
            <Route path='/filtre' element={<FilterScreen />} />
          </Route>

          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/logout" element={<LogoutScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
