import React from 'react'
import { useEffect, useState } from 'react'
import { CircularProgress, createTheme, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { ListItemText, IconButton } from "@material-ui/core";
import axios from 'axios'
import { StyledMenu, StyledMenuItem } from "./StyledMenu";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Loader from './Loader';
import CustomTableFooter from './CustomTableFooter';
import languages from '../config/languages.json';
import { ExpType, ExpTypeFilter, tableTheme, rowsPerPageOptions, producerOptions } from "../config/config.json"
import TableViewCol from './TableViewCol';
import { useDispatch, useSelector } from 'react-redux'
import { appStateAction } from '../actions/appStateActions';
import AddTerm from './AddTerm';
import PopUp from './PopUp';
import AddTermRelation from '../components/AddTermRelation';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@material-ui/styles";


import {
  FormGroup,
  TextField,
  FormLabel
} from '@mui/material';
import AddSC from './AddSC';
import { getCookie } from '../utils/utils';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const myTheme = createTheme(tableTheme);

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: "100%"
    }
  }),
  { name: "myStyles" }
)

function TermSearch() {
  const classes = useStyles();
  // const [forms, setForms] = useState([])
  const [selectedForm, setSelectedForm] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const [myTableState, setMyTableState] = useState({
    rowsPerPage: 20
  })

  const [tableBodyHeight, setTableBodyHeight] = useState(
    // (document.getElementsByClassName("lm_content")[2] != undefined) ? parseInt(document.getElementsByClassName("lm_content")[2].style.height, 10) - 200 + "px" : 600
  )

  const [isLoaded, setIsLoaded] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [thisPage, setThisPage] = useState(0)
  const [postagRegex, setPostagRegex] = useState('')
  const [surfaceRegex, setSurfaceRegex] = useState('')
  const [lemmaRegex, setLemmaRegex] = useState('')
  const [languageFilter, setLanguageFilter] = useState([])
  const [producerFilter, setProducerFilter] = useState([])
  const [producerRegex, setProducerRegex] = useState('')
  const [expTypeFilter, setExpTypeFilter] = useState([])
  const [validationFilter, setValidationFilter] = useState([])
  const [words_lte, setWords_lte] = useState()
  const [words_gte, setWords_gte] = useState()

  const [occurences_gte, setOccurences_gte] = useState()
  const [occurences_lte, setOccurences_lte] = useState()

  const [n_doc_gte, setN_doc_gte] = useState()
  const [n_doc_lte, setN_doc_lte] = useState()

  const [headRegex, setHeadRegex] = useState('')
  const [expRegex, setExpRegex] = useState('')

  const [isVedette, setIsVedette] = useState(0)
  const [inSemantic, setiInSemantic] = useState(0)

  const [resizableColumns, setResizableColumns] = useState(false);

  const [formfilterList, setformfilterList] = useState();
  const [lemmafilterList, setlemmafilterList] = useState([]);
  const [posTaggingfilterList, setposTaggingfilterList] = useState([]);
  const [languagefilterList, setlanguagefilterList] = useState([]);
  const [analysisfilterList, setanalysisfilterList] = useState([]);
  const [nth_expfilterList, setnth_expfilterList] = useState([]);
  const [expansiontypefilterList, setexpansiontypefilterList] = useState([]);
  const [nth_headfilterList, setnth_headfilterList] = useState([]);
  const [relationsfilterList, setrelationsfilterList] = useState([]);

  const [sort, setSort] = useState([])

  const dispatch = useDispatch()
  const myAppState = useSelector(state => state.appState)

  const [id, setId] = useState(null);
  const [smShow, setSmShow] = useState(false);

  const csrftoken = getCookie('csrftoken');

  const profileInfo = useSelector(state => state.profile)
  const { projectRole } = profileInfo
  const pId = profileInfo ? projectRole.projectId : undefined
  const role = profileInfo ? projectRole.role : undefined


  useEffect(() => {
    setIsLoading(true)
    async function fetchTerms() {
      await axios.post(`/p/${pId}/terms/?limit=${myTableState.rowsPerPage}`, {
        'postag_regex': postagRegex,
        'surface_regex': surfaceRegex,
        'lemma_regex': lemmaRegex,
        'producer_regex': producerRegex,
        'language_in': (languageFilter[0] === undefined || languageFilter[0].length === 0) ? undefined : languageFilter,
        'producer_in': (producerFilter[0] === undefined || producerFilter[0].length === 0) ? undefined : producerFilter,
        'exp_in': (expTypeFilter[0] === undefined || expTypeFilter[0].length === 0) ? undefined : expTypeFilter,
        'head_regex': headRegex,
        'exp_regex': expRegex,
        'sort': sort,
        'words_lte': words_lte,
        'words_gte': words_gte,
        'occurences_gte': occurences_gte,
        'occurences_lte': occurences_lte,
        'n_doc_gte': n_doc_gte,
        'n_doc_lte': n_doc_lte,
        'is_vedette': (isVedette !== 0) ? isVedette : undefined,
        'in_semantic': (inSemantic !== 0) ? inSemantic : undefined,
        'status_in': (validationFilter[0] === undefined || validationFilter[0].length === 0) ? undefined : validationFilter
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        }
      }
      )
        .then(res => {
          dispatch(appStateAction({
            ...myAppState,
            forms: res.data.data,
            refresh: false,
            AddTermIsOpen: false,
            duplicateFormIsOpen: false,
            addRelationIsOpen: false,
            addSCIsOpen: false,
            addSCTermIsOpen: false,
            metaTerms: {
              ...myAppState.metaTerms,
              postagRegex,
              surfaceRegex,
              lemmaRegex,
              producerRegex,
              language_in: (languageFilter[0] === undefined || languageFilter[0].length === 0) ? undefined : languageFilter,
              producer_in: (producerFilter[0] === undefined || producerFilter[0].length === 0) ? undefined : producerFilter,
              exp_in: (expTypeFilter[0] === undefined || expTypeFilter[0].length === 0) ? undefined : expTypeFilter,
              headRegex,
              expRegex,
              words_lte,
              words_gte,
              occurences_gte,
              occurences_lte,
              n_doc_gte,
              n_doc_lte,
              inSemantic,
              isVedette
            }
          }))
          setCount(res.data.count)
          setIsLoaded(false)
          setIsLoading(false)
        })
        .catch(
          function (error) {
            setIsLoaded(false)
            setIsLoading(false)
            return Promise.reject(error)
          }
        )
    }
    fetchTerms()
    // dispatch(appStateAction({
    //   ...myAppState,

    // }))
  }, [myAppState.refresh, postagRegex, surfaceRegex, lemmaRegex, languageFilter, producerFilter, producerRegex, headRegex, expRegex, expTypeFilter, validationFilter, words_lte, words_gte, occurences_gte, occurences_lte, n_doc_gte, n_doc_lte, inSemantic, isVedette])

  async function handleDelete(id) {
    await fetch(`/p/${pId}/terms/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
    });

    axios.post(`/p/${pId}/terms/?limit=${myTableState.rowsPerPage}&offset=${myTableState.rowsPerPage * thisPage}`, {
      'postag_regex': postagRegex,
      'surface_regex': surfaceRegex,
      'lemma_regex': lemmaRegex,
      'producer_regex': producerRegex,
      'language_in': (languageFilter[0] === undefined || languageFilter[0].length === 0) ? undefined : languageFilter,
      'producer_in': (producerFilter[0] === undefined || producerFilter[0].length === 0) ? undefined : producerFilter,
      'exp_in': (expTypeFilter[0] === undefined || expTypeFilter[0].length === 0) ? undefined : expTypeFilter,
      'head_regex': headRegex,
      'exp_regex': expRegex,
      'sort': sort,
      'words_lte': words_lte,
      'words_gte': words_gte,
      'occurences_gte': occurences_gte,
      'occurences_lte': occurences_lte,
      'n_doc_gte': n_doc_gte,
      'n_doc_lte': n_doc_lte,
      'is_vedette': (isVedette !== 0) ? isVedette : undefined,
      'in_semantic': (inSemantic !== 0) ? inSemantic : undefined
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      }
    })
      .then(res => {
        dispatch(appStateAction({ ...myAppState, forms: res.data.data, form: 0 }))
        setCount(res.data.count)
        setIsLoaded(false)
      })
      .catch(
        function (error) {
          setIsLoaded(false)
          return Promise.reject(error)
        }
      )
  }

  const columns = [
    ... (role > 0) ? [{
      name: "actions",
      label: "Actions",
      options: {
        viewColumns: true,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <IconButton
                size='small'
                sx={{ padding: "0px !important" }}
                onClick={(e) => {
                  if (role > 0) handleClickMenu(e);
                  fetchTerm(myAppState.forms[tableMeta.rowIndex].id)

                }}
              >
                <MenuIcon />
              </IconButton>
              <StyledMenu
                id={`customized-menu-${tableMeta.rowIndex}`}
                key={tableMeta.rowIndex}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <StyledMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    dispatch(appStateAction({ ...myAppState, form: selectedForm, addSCTermIsOpen: true }))
                  }}
                >
                  <AddIcon />
                  <ListItemText primary="Créer la classe" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    dispatch(appStateAction({ ...myAppState, form: selectedForm, duplicateFormIsOpen: true }))

                  }}
                >
                  <ContentCopyIcon />
                  <ListItemText primary="Modifier le terme" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    dispatch(appStateAction({ ...myAppState, form: selectedForm, addRelationIsOpen: true }))
                  }}
                >
                  <CompareArrowsIcon />
                  <ListItemText primary="Ajouter une relation entre termes" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    if (!selectedForm.vedette) handleDelete(selectedForm.id); else setSmShow(true)
                    dispatch(appStateAction({ ...myAppState, form: 0, detailsTab: false }))
                  }}
                >
                  <DeleteIcon />
                  <ListItemText primary="Supprimer" />
                </StyledMenuItem>
              </StyledMenu>
            </div >
          );
        }
      }
    }] : [],
    {
      name: "referenceId",
      label: "Forme",
      options: {
        filter: true,
        sort: true,
        filterList: formfilterList,
        customBodyRender: (referenceId, meta) => (
          <Droppable droppableId="term" isDropDisabled={true}>
            {(provided) => (
              <div>
                <div className='droppable-box'>
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Draggable
                      key={referenceId[0]} draggableId={`{"id": ${referenceId[0]}, "type": "term"}`} index={referenceId[0]}
                    >
                      {(provided) => (

                        <div style={{ cursor: "pointer" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {referenceId[1]}
                        </div>
                      )}
                    </Draggable>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        ),
      },
    },
    {
      name: "lemma",
      label: "Lemme",
      options: {
        filter: true,
        sort: true,
        filterList: lemmafilterList
      },
    },
    {
      name: "posTagging",
      label: "POS Tagging",
      options: {
        filter: true,
        sort: true,
        filterList: posTaggingfilterList
      },
    },
    {
      name: "language",
      label: "Langue",
      options: {
        filter: true,
        sort: false,
        filterList: languagefilterList
      },
    },
    {
      name: "analysis",
      label: "Analyse syntaxique",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (analysis, meta) => (<>[{analysis}]</>),
        filterList: analysisfilterList
      },
    },
    {
      name: "nth_exp",
      label: "Expansion",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (nth_head, meta) => ((nth_head !== null && nth_head !== undefined) ? <>{nth_head.reference}</> : ""),
        filterList: nth_expfilterList
      },
    },
    {
      name: "expansion type",
      label: "Position",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (type, meta) => (
          ExpType[type]
        ),
        filterList: expansiontypefilterList
      },
    },
    {
      name: "nth_head",
      label: "Tête",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (nth_exp, meta) => ((nth_exp !== null && nth_exp !== undefined) ? <>{nth_exp.reference}</> : ""),
        filterList: nth_headfilterList
      },
    },
    {
      name: "relations",
      label: "Autres termes liés",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (relations, meta) => {
          if (relations !== undefined) return Object.entries(relations).map(relation =>
            <>{relation[1]}<br /></>
          )
        },
        setCellProps: () => ({ style: { minWidth: "300px", maxWidth: "700px" } }),
        filterList: relationsfilterList

      },
    },
    {
      name: "vedette",
      label: "Vedette",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (vedette, meta) => ((vedette !== null) ? "Oui" : "Non"),
      },
    },
    {
      name: "semantic",
      label: "Label de classe",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (semantic, meta) => ((semantic !== undefined && semantic.length !== undefined) ? "Oui" : "Non"),
      },
    },
    {
      name: "docs",
      label: "Nb de documents",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (documents, meta) => { if (documents !== undefined) return documents.docs },
      },
    },
    {
      name: "words",
      label: "Nb de mots",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "occurences",
      label: "Nb d'occurrences",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (documents, meta) => { if (documents !== undefined) return documents.occurences },
      },
    },
    {
      name: "head_pdty",
      label: "Productivité tête",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "exp_pdty",
      label: "Productivité expansion",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "relevanceScore",
      label: "Pertinence",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "producerLabelId",
      label: "Producteur",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Validation",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (status, meta) => {
          return (
            <input type="checkbox" checked={status} onClick={(e) => onClickValidation(e, myAppState.forms[meta.rowIndex])}></input>
          );
        }
      },
    },
  ];
  const options = {
    setRowProps: (row, index) => { return { style: { backgroundColor: index === id ? '#b0e0e6' : "", cursor: 'pointer' }, }; },
    onRowClick: (rowData, rowMetaData) => { const id = rowMetaData.rowIndex; setId(id) },
    filterType: "textField",
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    filter: false,
    search: false,
    print: false,
    download: false,
    rowsPerPage: (myTableState === undefined) ? 20 : myTableState.rowsPerPage,
    rowsPerPageOptions,
    viewColumns: true,
    // responsive: "vertical",
    count,
    tableBodyHeight: "calc(100% - 120px)",
    // onRowClick: onRowClick,
    onCellClick: onCellClick,
    serverSide: true,
    draggableColumns: {
      enabled: true,
      transitionTime: 100,
    },
    resizableColumns: resizableColumns,
    textLabels: {
      body: {
        noMatch: isLoaded ? <Loader>Chargement des données ...</Loader> : 'Désolé, il n\'y a pas de données correspondantes à afficher'
      },
      toolbar: {
        viewColumns: 'Affichage colonnes',
      },
      viewColumns: {
        title: 'Affichage colonnes',
        titleAria: 'Affichage colonnes',
      },
      pagination: {
        rowsPerPage: "Termes par page",
        displayRows: "de",
        jumpToPage: "Sauter à la page"
      },
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomTableFooter
          count={count}
          page={thisPage}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changepage}
          textLabels={textLabels}
        />
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changeRowsPerPage':
          setMyTableState({
            rowsPerPage: tableState.rowsPerPage,
          })
          changepage(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
          break;

        case 'sort':
          changepage(tableState.page, tableState.rowsPerPage, tableState.sortOrder)
          break;
        default:
          break;
      }


    }
  }



  function changepage(page, rowsPerPage, sortOrder) {
    let sortParam = []
    if (sortOrder !== undefined) switch (sortOrder.name) {
      case "referenceId":
        if (sortOrder.direction === "desc") {
          sortParam = [`-${"reference"}`]
          setSort([`-${"reference"}`])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["reference"]
          setSort(["reference"])
        }
        break;
      case "lemma":
        if (sortOrder.direction === "desc") {
          sortParam = ["-form__lemma"]
          setSort(["-form__lemma"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["form__lemma"]
          setSort(["form__lemma"])
        }
        break;
      case "posTagging":
        if (sortOrder.direction === "desc") {
          sortParam = ["-form__posTagging"]
          setSort(["-form__posTagging"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["form__posTagging"]
          setSort(["form__posTagging"])
        }
        break;
      case "relevanceScore":
        if (sortOrder.direction === "desc") {
          sortParam = ["-form__relevanceScore"]
          setSort(["-form__relevanceScore"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["form__relevanceScore"]
          setSort(["form__relevanceScore"])
        }
        break;
      case "exp_pdty":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__exppdt"]
          setSort(["-properties__exppdt"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__exppdt"]
          setSort(["properties__exppdt"])
        }
        break;
      case "head_pdty":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__headpdt"]
          setSort(["-properties__headpdt"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__headpdt"]
          setSort(["properties__headpdt"])
        }
        break;
      case "nth_exp":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__expansion__reference"]
          setSort(["-properties__expansion__reference"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__expansion__reference"]
          setSort(["properties__expansion__reference"])
        }
        break;
      case "nth_head":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__headTerm__reference"]
          setSort(["-properties__headTerm__reference"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__headTerm__reference"]
          setSort(["properties__headTerm__reference"])
        }
        break;

      case "words":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__words"]
          setSort(["-properties__words"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__words"]
          setSort(["properties__words"])
        }
        break;

      case "docs":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__n_doc"]
          setSort(["-properties__n_doc"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__n_doc"]
          setSort(["properties__n_doc"])
        }
        break;

      case "occurences":
        if (sortOrder.direction === "desc") {
          sortParam = ["-properties__occurences"]
          setSort(["-properties__occurences"])
        }
        else if (sortOrder.direction === "asc") {
          sortParam = ["properties__occurences"]
          setSort(["properties__occurences"])
        }
        break;



      default:
        break;
    }



    setThisPage(page)
    setIsLoading(true)
    async function fetchTerms(limit = 10, offset = 0) {
      await axios.post(`/p/${pId}/terms/?limit=${limit}&offset=${offset}`, {
        'postag_regex': postagRegex,
        'surface_regex': surfaceRegex,
        'lemma_regex': lemmaRegex,
        'producer_regex': producerRegex,
        'language_in': (languageFilter[0] === undefined || languageFilter[0].length === 0) ? undefined : languageFilter,
        'producer_in': (producerFilter[0] === undefined || producerFilter[0].length === 0) ? undefined : producerFilter,
        'exp_in': (expTypeFilter[0] === undefined || expTypeFilter[0].length === 0) ? undefined : expTypeFilter,
        'head_regex': headRegex,
        'exp_regex': expRegex,
        'sort': (isEmpty(sortParam) ? sort : sortParam),
        'words_lte': words_lte,
        'words_gte': words_gte,
        'occurences_gte': occurences_gte,
        'occurences_lte': occurences_lte,
        'n_doc_gte': n_doc_gte,
        'n_doc_lte': n_doc_lte,
        'is_vedette': (isVedette !== 0) ? isVedette : undefined,
        'in_semantic': (inSemantic !== 0) ? inSemantic : undefined
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        }
      })
        .then(res => {
          dispatch(appStateAction({ ...myAppState, forms: res.data.data }))
          setCount(res.data.count)
          setIsLoading(false)
        })
        .catch(
          function (error) {
            setIsLoaded(false)
            setIsLoading(false)
            return Promise.reject(error)
          }
        )
    }
    const offset = (rowsPerPage === undefined) ? page * myTableState.rowsPerPage : thisPage * rowsPerPage


    dispatch(appStateAction({
      ...myAppState,
      metaTerms: {
        ...myAppState.metaTerms,
        limit: (rowsPerPage === undefined) ? myTableState.rowsPerPage : rowsPerPage,
        offset,
        sort: (isEmpty(sortParam) ? sort : sortParam),

      }
    }))
    fetchTerms((rowsPerPage === undefined) ? myTableState.rowsPerPage : rowsPerPage, offset)
  }


  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  async function fetchTerm(id) {

    const { data } = await axios.get(`/p/${pId}/terms/${id}`)
    setSelectedForm(data.data);
    dispatch(appStateAction({ ...myAppState, form: data.data, contextTab: true, detailsTab: true }))
  }

  function onCellClick(colData, cellMeta) {
    if (cellMeta.colIndex !== 0) {
      fetchTerm(myAppState.forms[cellMeta.rowIndex].id)
    }
  }


  function isEmpty(obj) {
    for (let i in obj) return false;
    return true;
  }

  async function onClickValidation(e, termSelected) {
    if (!isLoading) {
      setIsLoading(true)
      const term = {status: e.target.checked};
      fetch(`/p/${pId}/terms/validate/${termSelected.id}/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(term),
      })
        .then(res => {
          dispatch(appStateAction({ ...myAppState, refresh: true }));
          setIsLoading(false)
        })
    }
  }

  // const [observed, setObserved] = useState(false)

  // if (!isEmpty(myAppState.form) && !observed) {
  //   document.getElementsByClassName("lm_content")[2].style.height = '550px'
  //   document.getElementsByClassName("lm_item_container")[2].style.height = '550px'
  //   document.getElementsByClassName("lm_items")[2].style.height = '550px'
  //   document.getElementsByClassName("lm_stack")[2].style.height = '587px'

  //   document.getElementsByClassName("lm_content")[3].style.height = "362px"
  //   document.getElementsByClassName("lm_item_container")[3].style.height = "362px"
  //   document.getElementsByClassName("lm_items")[3].style.height = "362px"
  //   document.getElementsByClassName("lm_stack")[3].style.height = "399px"
  //   setObserved(true)
  // }

  // var target = document.querySelectorAll('.lm_content');
  // var config = { attributes: true, childList: true, characterData: true }

  // var observer = new MutationObserver(function (mutations) {
  //   mutations.forEach(function () {
  //     if ((document.getElementsByClassName("lm_content")[2] !== undefined)) {

  //       setTableBodyHeight((parseInt(document.getElementsByClassName("lm_content")[2].style.height, 10) - 200 + "px"))
  //     } else if ((document.getElementsByClassName("lm_content")[0] !== undefined)) {
  //       setTableBodyHeight((parseInt(document.getElementsByClassName("lm_content")[0].style.height, 10) - 200 + "px"))
  //     }
  //   });
  // });

  // if ((document.getElementsByClassName("lm_content")[2] !== undefined)) {
  //   observer.observe(target[2], config)
  // } else {
  //   observer.observe(target[0], config);
  // }

  const toggleAddRelationPopup = () => {
    dispatch(appStateAction({ ...myAppState, addRelationIsOpen: false }))
  }

  const toggleAddTermPopup = () => {
    dispatch(appStateAction({ ...myAppState, AddTermIsOpen: false }))
  }

  const toggleAddSCPopup = () => {
    dispatch(appStateAction({ ...myAppState, addSCIsOpen: false }))
  }

  const toggleAddSCTermPopup = () => {
    dispatch(appStateAction({ ...myAppState, addSCTermIsOpen: false }))
  }

  const toggleDuplicateFormPopup = () => {
    dispatch(appStateAction({ ...myAppState, duplicateFormIsOpen: false }))
  }

  const onChangeValidationFilter = (value) => {
    if (value === 'true') setValidationFilter([true])
    else if (value === 'false') setValidationFilter([false])
    else setValidationFilter([value])
  }


  return (
    <div style={{ flex: 1, height: "calc(100% - 45px)" }}>
      <Modal
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="deleteTermVedette"
        dialogClassName="w-75"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body closeButton>Vous ne pouvez pas supprimer ce terme car il est vedette d'une classe.</Modal.Body>
      </Modal>
      {(role > 0) && <div>
        <Button className='m-1 p-1' size="sm" type="submit" onClick={() => dispatch(appStateAction({ ...myAppState, AddTermIsOpen: true }))}><i className="fas fa-plus"></i> Terme</Button>
        <Button className='m-1 p-1' size="sm" type="submit" onClick={() => dispatch(appStateAction({ ...myAppState, addSCIsOpen: true }))}><i className="fas fa-plus"></i> Classe sémantique</Button>
      </div>}

      {myAppState.addRelationIsOpen && <PopUp
        content={<>
          <AddTermRelation />
        </>}
        handleClose={toggleAddRelationPopup}
      />}
      {myAppState.AddTermIsOpen && <PopUp
        content={<>
          <AddTerm />
        </>}
        handleClose={toggleAddTermPopup}
      />}
      {myAppState.addSCIsOpen && <PopUp
        content={<>
          <AddSC />
        </>}
        handleClose={toggleAddSCPopup}
      />}
      {myAppState.addSCTermIsOpen && <PopUp
        content={<>
          <AddSC />
        </>}
        handleClose={toggleAddSCTermPopup}
      />}
      {myAppState.duplicateFormIsOpen && <PopUp
        content={<>
          <AddTerm />
        </>}
        handleClose={toggleDuplicateFormPopup}
      />}
      <MuiThemeProvider theme={myTheme}>
        <MUIDataTable
          // title={
          //   <Typography variant="h6">
          //     Tableau des termes
          //     {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
          //   </Typography>
          // }
          className={classes.root}
          title={
            <div>
              <Row >
                <Col
                  lg={2}
                  xl={1}
                  className="mt-1 mx-0 px-0"
                >
                  {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                </ Col>
                <Col
                  lg={2}
                  xl={1}
                  className="pr-1 mt-1 ml-0 pl-0"
                >
                  <Form.Control size="sm" type="text" value={surfaceRegex} onChange={({ target }) => setSurfaceRegex(target.value)} placeholder="Forme..." />
                </ Col>
                <Col
                  lg={2}
                  xl={1}
                  className="px-1 mt-1"
                >
                  <Form.Control size="sm" type="text" value={lemmaRegex} onChange={({ target }) => setLemmaRegex(target.value)} placeholder="Lemme" />
                </ Col>
                <Col
                  lg={2}
                  xl={1}
                  className="px-1 mt-1"
                >
                  <Form.Control size="sm" type="text" value={postagRegex} onChange={({ target }) => setPostagRegex(target.value)} placeholder="Pos tagging" />
                </ Col>

                <Col
                  lg={2}
                  xl={1}
                  className="px-1 mt-1"
                >
                  <Form.Control size="sm" type="text" value={producerRegex} onChange={({ target }) => setProducerRegex(target.value)} placeholder="Producteur" />
                </ Col>

                <Col
                  lg={2}
                  xl={1}
                  className="pr-1 mt-1 ml-0 pl-0"
                >
                  <Form.Control size="sm" type="text" value={headRegex} onChange={({ target }) => setHeadRegex(target.value)} placeholder="Tête" />
                </ Col>

                <Col
                  lg={2}
                  xl={1}
                  className="pr-1 mt-1 ml-0 pl-0"
                >
                  <Form.Control size="sm" type="text" value={expRegex} onChange={({ target }) => setExpRegex(target.value)} placeholder="Expansion" />
                </ Col>
                <Col lg={2}
                  xl={1} className="px-1 mt-1">
                  <Form.Control as="select" size="sm" aria-label="select language" value={expTypeFilter[0]} onChange={(e) => setExpTypeFilter([e.target.value])}>
                    <option value={[]}>Type...</option>
                    {ExpTypeFilter.map((type => (
                      <option value={Object.keys(type)}>{Object.values(type)}</option>
                    )))}
                  </Form.Control>
                </ Col>
                <Col lg={2}
                  xl={1} className="px-1 mt-1">
                  <Form.Control as="select" size="sm" aria-label="select language" value={languageFilter[0]} onChange={(e) => setLanguageFilter([e.target.value])}>
                    <option value={[]}>Langue...</option>
                    {languages.map((language => (
                      <option value={Object.keys(language)}>{Object.values(language)}</option>
                    )))}
                  </Form.Control>
                </ Col>
                <Col lg={2}
                  xl={1} className="px-1 mt-1 mr-1">
                  <Form.Control as="select" size="sm" aria-label="select producer" value={producerFilter[0]} onChange={(e) => setProducerFilter([e.target.value])}>
                    <option value={[]}>Source...</option>
                    {producerOptions.map((producerOption => (
                      <option value={Object.keys(producerOption)}>{Object.values(producerOption)}</option>
                    )))}
                  </Form.Control>
                </ Col>
                <Col lg={2}
                  xl={1} className="px-1 mt-1 mr-1">
                  <Form.Control as="select" size="sm" aria-label="select validation" value={validationFilter[0]} onChange={(e) => onChangeValidationFilter(e.target.value)}>
                    <option value={[]}>Valid...</option>
                    <option value={true}>Oui</option>
                    <option value={false}>Non</option>
                  </Form.Control>
                </ Col>
              </Row>
              <Row>
                <Col lg={4}
                  xl={3} className="px-1 mt-1 ml-2">
                  <FormGroup row>
                    <FormLabel className="mt-2">Nb de mots: </FormLabel>

                    <TextField
                      label='min'
                      size='small'
                      className='textFieldComp'
                      value={words_gte} onChange={({ target }) => setWords_gte(target.value)}
                      style={{ width: '27%', padding: "9px", marginRight: '1%' }}
                    />
                    <TextField
                      label='max'
                      size='small'
                      className='textFieldComp'
                      value={words_lte} onChange={({ target }) => setWords_lte(target.value)}
                      style={{ width: '27%', padding: "9px" }}
                    />

                  </FormGroup>
                </ Col>
                <Col lg={4}
                  xl={3} className="px-1 mt-1 ml-1">
                  <FormGroup row>
                    <FormLabel className="mt-2">Nb de docs: </FormLabel>

                    <TextField
                      label='min'
                      size='small'
                      className='textFieldComp'
                      value={n_doc_gte} onChange={({ target }) => setN_doc_gte(target.value)}
                      style={{ width: '27%', padding: "9px", marginRight: '1%' }}
                    />
                    <TextField
                      label='max'
                      size='small'
                      className='textFieldComp'
                      value={n_doc_lte} onChange={({ target }) => setN_doc_lte(target.value)}
                      style={{ width: '27%', padding: "9px" }}
                    />

                  </FormGroup>
                </ Col>
                <Col lg={6} xl={3} className="px-1 mt-1 ml-1">
                  <FormGroup row>
                    <FormLabel className="mt-2">Nb d'occurences: </FormLabel>

                    <TextField
                      label='min'
                      size='small'
                      className='textFieldComp'
                      value={occurences_gte} onChange={({ target }) => setOccurences_gte(target.value)}
                      style={{ width: '20%', padding: "9px" }}
                    />
                    <TextField
                      label='max'
                      size='small'
                      className='textFieldComp'
                      value={occurences_lte} onChange={({ target }) => setOccurences_lte(target.value)}
                      style={{ width: '20%', padding: "9px" }}
                    />

                  </FormGroup>
                </ Col>

                <Col lg={2}
                  xl={1} className="px-1 mt-2">
                  <TextField
                    id="outlined-select-inSemantic"
                    select
                    label="Label de classe"
                    size="small"
                    style={{
                      width: '90%',
                      padding: "0px"
                    }}
                    className="dropDownOptions"
                    value={inSemantic}
                    onChange={(event) => {
                      setiInSemantic(event.target.value)
                    }}
                  >
                    <MenuItem key={1} value={0}> - </MenuItem>
                    <MenuItem key={2} value={true}> Oui </MenuItem>
                    <MenuItem key={3} value={false}> Non </MenuItem>
                  </TextField>
                </ Col>
                <Col lg={2}
                  xl={1} className="px-1 mt-2">
                  <TextField
                    id="outlined-select-isVedette"
                    select
                    label="Vedette"
                    size="small"
                    style={{
                      width: '90%',
                      padding: "0px"
                    }}
                    className="dropDownOptions"
                    value={isVedette}
                    onChange={(event) => {
                      setIsVedette(event.target.value)
                    }}
                  >
                    <MenuItem key={1} value={0}> - </MenuItem>
                    <MenuItem key={2} value={true}> Oui </MenuItem>
                    <MenuItem key={3} value={false}> Non </MenuItem>
                  </TextField>
                </ Col>
              </Row>
            </div>
          }
          data={(myAppState.forms === null) ? [] : myAppState.forms.map((form) => {
            if (form.form !== null) {
              return {
                ...form,
                ...form.form,
                ...form.form.source,
                producerLabelId: form.form.source.label,
                ...form.documents,
                referenceId: [form.id, form.reference]
              }
            }
            else return {
              ...form,
              ...form.form,
            }
          }
          )}
          columns={columns}
          options={options}
          components={{
            TableViewCol: TableViewCol
          }}
        />
      </MuiThemeProvider >
    </div >
  )
}

export default TermSearch
