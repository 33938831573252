import {
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    PROFILE_RESET,
    ROLE_REQUEST,
    ROLE_SUCCESS,
    ROLE_FAIL,
    ROLE_RESET,
} from '../constants/profileConstants'

export const profileReducer = (state = { profile: {}, projectRole: {} }, action) => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return { ...state, loading: true }

        case PROFILE_SUCCESS:
            return { ...state, loading: false, loaded: true, authenticated: true, profile: action.payload }

        case ROLE_SUCCESS:
            return { ...state, projectRole: action.payload }

        case PROFILE_FAIL:
            return { loading: false, loaded: true, error: action.payload }

        case PROFILE_RESET:
            return { profile: {} }

        default:
            return state
    }
}