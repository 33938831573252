import {
    SELECTED_SC_SUCCESS
} from '../constants/selectedSCConstants'

export const selectedSCReducer = (state = {}, action) => {
    switch (action.type) {
        case SELECTED_SC_SUCCESS:
            return { ...action.payload }

        default:
            return state
    }

}
