import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loader from './../components/Loader';
import Message from './../components/Message';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';


function TasksStatusScreen() {
    const [tasks, setTasks] = useState()
    const [exports, setExports] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingExports, setLoadingExports] = useState(false)
    const [error, setError] = useState()

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined
    const [refresh, setRefresh] = useState(false)


    useEffect(() => {
        async function fetchTasksStatus() {
            setLoading(true)
            await axios.get('/p/' + pId + '/tasks')
                .then(res => {
                    setTasks(res.data)
                    setLoading(false)
                })
                .catch(
                    function (error) {
                        // setError(error)
                        setLoading(false)
                        return Promise.reject(error)
                    }
                )
        }
        async function fetchExports() {
            setLoadingExports(true)
            await axios.get('/p/' + pId + '/export/')
                .then(res => {
                    setExports(res.data.data)
                    setLoadingExports(false)
                })
                .catch(
                    function (error) {
                        setLoadingExports(false)
                        return Promise.reject(error)
                    }
                )
        }
        fetchTasksStatus()
        fetchExports()
    }, [refresh])

    const refreshAfterDownlaod = () => {
        window.setTimeout(() => {
            setRefresh(!refresh)
        }, 500)
    }

    console.log("logme", exports);

    return (
        <div className="container-element container text-center mt-5">
            <h4 className="mb-3">Statut des tâches</h4>
            {(loading && loadingExports) ?
                <Loader /> :
                error ?
                    (<Message variant='danger'>{error}</Message>
                    ) : (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type de traitement</th>
                                    <th>Nom du ficher</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks && tasks.map(task => (
                                    <tr key={task.id}>
                                        <td>{task.task_id}</td>
                                        <td>
                                            {
                                                (task.type === "UP-YATEA") && <p>Import YATEA</p>
                                            }
                                            {
                                                (task.type === "UP-TSUIT") && <p>Import TermSuite</p>
                                            }
                                            {
                                                (task.type === "UP-LISTS") && <p>Import de liste à plat</p>
                                            }
                                            {
                                                (task.type === "UP-DOC") && <p>Import de documents</p>
                                            }
                                            {
                                                (task.type === "UP-SKOS") && <p>Import SKOS</p>
                                            }
                                            {
                                                (task.type === "FUSION") && <p>Fusion</p>
                                            }
                                            {
                                                (task.type === "FILTER") && <p>Filtre</p>
                                            }
                                            {
                                                (task.type === "EXPORT") && <p>Export</p>
                                            }
                                        </td>
                                        <td>{task.file.name}</td>
                                        <td>{task.date_created.substring(0, 10)} {task.date_created.substring(11, 19)}</td>
                                        <td>
                                            {(() => {
                                                switch (task.status) {
                                                    case "SUCCESS": return "SUCCÈS"
                                                    case "FAILURE": return "ÉCHEC";
                                                    case "PROGRESS": return "En cours";
                                                    case "STARTED": return "En cours";
                                                    default: return task.status;
                                                }
                                            })()}
                                        </td>
                                        {(task.status === 'SUCCESS') ? <td>
                                            {
                                                (task.type === "UP-YATEA") && task.results && <p>{task.results.term} termes ajoutés</p>
                                            }
                                            {
                                                (task.type === "UP-TSUIT") && task.results && <p>{task.results.term} termes ajoutés</p>
                                            }
                                            {
                                                (task.type === "UP-LISTS") && task.results && <p>{task.results.term} termes ajoutés</p>
                                            }
                                            {
                                                (task.type === "UP-DOC") && task.results && <p>{task.results.added} documents ajoutés</p>
                                            }
                                            {
                                                (task.type === "UP-SKOS") && task.results && <p>{task.results.semantic} classes sémantiques ajoutées</p>
                                            }
                                            {
                                                (task.type === "FUSION") && task.results && <p>{`${task.results.distinct} termes distincts ${task.results.replaced} termes remplacés !`}</p>
                                            }
                                            {
                                                (task.type === "FILTER") && task.results && <p>{`${task.results.rejected} termes rejetés !`}</p>
                                            }
                                            {
                                                (task.type === "EXPORT") && task.results && <>
                                                    <p>
                                                        {`${task.results.message_fr}`}
                                                    </p>

                                                    {exports.map((item) => {
                                                        if (item.task == task.task_id)
                                                            return <a
                                                                href={`/p/${pId}/export/${item.id}`}
                                                                download
                                                                onClick={refreshAfterDownlaod}
                                                            >
                                                                Télécharger le fichier
                                                            </a>
                                                        else if (task.status == "SUCCESS")
                                                            return <p style={{ color: "#A8A8A8" }}>
                                                                Fichier téléchargé !
                                                            </p>
                                                    })}
                                                    {(exports.length == 0) && (task.status == "SUCCESS") &&
                                                        <p style={{ color: "#A8A8A8" }}>
                                                            Fichier téléchargé !
                                                        </p>
                                                    }
                                                </>
                                            }
                                        </td> : (task.status === 'FAILURE') ? <td>{task.results.exc_fr}</td> : <td></td>
                                        }

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
        </div>
    )
}

export default TasksStatusScreen
