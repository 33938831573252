import React from 'react'
import "./PopUp.css"

function PopUp({ handleClose, content }) {
    return (
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={handleClose}>x</span>
                {content}
            </div>
        </div>
    )
}

export default PopUp