import React, {
    useState
} from 'react';
import { useDropzone } from 'react-dropzone'
import { Button, Card, Alert, Row, Form, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import { labels } from '../config/config.json'
import languages from '../config/languages.json'
import { getCookie } from '../utils/utils';
import { useSelector } from 'react-redux';


function ListPlatImportScreen() {
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const d = new Date();

    const [lang, setLang] = useState(0)
    const [label, setLabel] = useState(`LISTS_${d.getFullYear()}${d.getMonth() + 1}${d.getDate()}${d.getHours()}${d.getMinutes()}`)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    const maxSize = 7e+8;
    const { isDragActive, getRootProps, getInputProps, acceptedFiles, isDragReject, fileRejections } = useDropzone({
        accept: ".csv",
        minSize: 0,
        maxSize
    });
    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize




    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
        }, 2000)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response;
        } else {
            throw Error(response.statusText);
        }
    }

    const csrftoken = getCookie('csrftoken');

    async function getDataFromFile() {
        if (acceptedFiles[0] !== undefined) {
            setIsLoading(true)
            setMessage("")
            let data = new FormData()
            data.append('file_uploaded', acceptedFiles[0])
            data.append('label', label)
            if (lang != 0) data.append('lang', lang)
            await fetch('/p/' + pId + '/upload/liste/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrftoken
                },
                body: data
            })
                .then(checkError)
                .then(res =>
                    res.json()
                ).then(data => {
                    setIsLoading(false)
                    onShowSuccessMessage()
                    setMessage(`Fichier uploadé avec succès`)
                })

                .catch((res) => {
                    setIsLoading(false)
                    onShowErrorMessage();
                    console.log(res.statusText);
                })

        }
        else {

        }

    }

    const Onclickbutton = () => {
        getDataFromFile();
    }

    const labelHandleChange = (event) => {
        setLabel(event.target.value)
    }

    return (
        <div className="container-element">
            <div className="container text-center mt-5">
                <h4 className="mb-3">Import de liste à plat</h4>
                <p className="mb-3">{labels.ListePlateImport}</p>
                <Alert className="mx-5" variant="success" show={showSuccess} >
                    Termes importés avec succès
                </Alert>
                <Alert className="mx-5" variant="danger" show={showError} >
                    Une erreur s'est produite lors de l'importation des termes
                </Alert>

                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive &&
                        <Card>
                            <Card.Body>Cliquez ici ou déposez un fichier pour l'importer !</Card.Body>
                        </Card>
                    }
                    {isDragActive && !isDragReject &&
                        <Card>
                            <Card.Body>Déposez votre fichier ici !</Card.Body>
                        </Card>
                    }
                    {isDragReject &&
                        <Card>
                            <Card.Body className="text-warning mt-2">Le type de fichier n'est pas accepté !</Card.Body>
                        </Card>
                    }
                    {isFileTooLarge && (
                        <div>
                            <div className="text-danger mt-2">Le fichier est trop volumineux !</div>
                            <div className="text-danger mt-2">La taille limite des fichiers est {maxSize / 1000000}Mo.</div>
                        </div>
                    )}
                </div>
                <ul className="list-group mt-2">
                    {acceptedFiles.length > 0 && !isFileTooLarge && acceptedFiles.map(acceptedFile => (
                        <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                            {acceptedFile.name}
                        </li>
                    ))}
                </ul>
                <Form className="mt-2 center" style={{ width: "70%" }}>
                    <Form.Group as={Row} className="mb-3" controlId="">
                        <Form.Label column sm="6" xs="12">
                            Libellé
                        </Form.Label>
                        <Col sm="6" xs="12">
                            <Form.Control
                                defaultValue={label}
                                onChange={labelHandleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="">
                        <Form.Label column sm="6" xs="12">
                            Langue
                        </Form.Label>
                        <Col sm="6" xs="12">
                            <Form.Control
                                as='select'
                                value={lang}
                                onChange={(e) => setLang(e.target.value)}
                            >
                                <option value={0}>Select...</option>
                                {languages.map((language => (
                                    <option value={Object.keys(language)}>{Object.values(language)}</option>
                                )))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
                {isLoading && <div className="m-2"><Loader>Import de termes ...</Loader></div>}
                <div><Button className="mt-2" onClick={Onclickbutton}>Import</Button></div>
                <p className="m-2">{message}</p>
            </div>
        </div>
    )
}

export default ListPlatImportScreen
