import {
    APP_STATE_SUCCESS,
} from '../constants/appStateConstants'

export const appStateAction = (data) => async (dispatch) => {
    dispatch({
        type: APP_STATE_SUCCESS,
        payload: data,
    })
}
