import React, {
    useState,
    useEffect
} from 'react';
import { Button, Alert, Form, Col, Row } from 'react-bootstrap';
import Loader from '../components/Loader';
import axios from 'axios'
import { getCookie } from '../utils/utils';
import { useSelector } from 'react-redux';


function FusionScreen() {
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    // const [producerList, setProducerList] = useState()
    // const [producerId, setProducerId] = useState()
    // const [validated, setValidated] = useState(false);

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined



    useEffect(() => {
        // async function fetchProducers() {
        //     await axios.get('/p/producer/')
        //         .then(res => {
        //             setProducerList(res.data.data)
        //         })
        //         .catch(
        //             function (error) {
        //                 return Promise.reject(error)
        //             }
        //         )
        // }
        // fetchProducers()
    }, [])

    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
        }, 2000)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    const csrftoken = getCookie('csrftoken');

    async function onClickButton() {
        // if (producerId !== undefined && producerId !== "") {
        setIsLoading(true)
        // const prop = {
        //     producer_id: (producerId === undefined) ? undefined : producerId,
        // };
        await fetch('/p/' + pId + '/fusion/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
            // body: JSON.stringify(prop),
        })
            .then(checkError)
            .then(data => {
                setIsLoading(false)
                onShowSuccessMessage()
                setMessage("Fusion en cours, veuillez vérifier la page des statuts des tâches.")
            })

            .catch((res) => {
                setIsLoading(false)
                onShowErrorMessage();
                console.log(res.statusText);
            })
        // }
        // setValidated(true);
    }

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }


    return (
        <div>

            <div className="container-element">
                <div className="container text-center mt-5">
                    <h4 className="mb-3">Fusion</h4>
                    <Alert className="mx-5" variant="success" show={showSuccess} >
                        Termes fusionnés avec succès
                    </Alert>
                    <Alert className="mx-5" variant="danger" show={showError} >
                        Une erreur s'est produite lors de la fusion des termes
                    </Alert>
                    <Form className="mt-3 mx-5" >
                        {/* <Form.Group as={Row} className="mb-3" controlId="">
                            <Form.Label column sm="6" xs="12">
                                Producteur
                            </Form.Label>
                            <Col sm="6" xs="12">
                                <Form.Control
                                    required
                                    as='select'
                                    value={producerId}
                                    onChange={(e) => setProducerId(e.target.value)}
                                >
                                    <option value=''>Select...</option>
                                    {(producerList !== undefined) && producerList.map((producer => (
                                        (producer.engine !== 'USERS') && <option value={producer.id}>{producer.label}{producer.id}</option>
                                    )))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Veuillez renseigner ce champ.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group> */}
                        {isLoading && <div className="m-2"><Loader>Fusion de termes ...</Loader></div>}
                        <div className="text-center"><Button className="mt-2" onClick={onClickButton} >Fusionner</Button></div>
                    </Form>
                    <p className="m-2">{message}</p>
                </div>
            </div>
        </div >
    )
}

export default FusionScreen
