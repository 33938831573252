import React, { useEffect, useState, useContext } from 'react'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import axios from 'axios'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from '../utils/utils';
import { appStateAction } from '../actions/appStateActions';
import { treeStateAction } from '../actions/treeStateActions';

function AddTermRelation() {
    const dispatch = useDispatch()
    const myAppState = useSelector(state => state.appState)
    const [term, setTerm] = useState(
        myAppState.form.id
    )
    const [termRelated, setTermRelated] = useState()
    const [relationName, setRelationName] = useState('')

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [validated, setValidated] = useState(false);

    const [terms, setTerms] = useState([])
    const [query, setQuery] = useState()
    const [loading, setLoading] = useState(false)

    const [checked, setchecked] = useState(false)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined


    const handleInputChange = query => {
        setTerms([])
        setQuery(query);
    };

    const csrftoken = getCookie('csrftoken');

    useEffect(() => {
        async function fetchTerms(limit = 10, offset = 0) {
            await axios.post(`/p/${pId}/terms/?limit=${limit}&offset=${offset}`, {
                'surface_regex': query ? `(^${query})` : "",
                'sort': (["reference"])
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
                .then(res => {
                    setTerms(res.data.data)
                    setLoading(true)
                })
                .catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                )
        }
        setLoading(false)
        if (query !== undefined && query.length !== 0) fetchTerms()
    }, [query])



    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            toggleAddRelationPopup()
        }, 500)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }



    const formHandleSubmit = event => {
        event.preventDefault();
        setchecked(true)
        if (term !== '' && termRelated !== undefined && term !== undefined && termRelated[0] !== '' && relationName !== '') {
            setValidated(true);
            setchecked(false);
            const relation = {
                Term: term,
                relationName: relationName,
                TermRelated: (termRelated[0] !== undefined) ? termRelated[0].id : null,
            };
            fetch('/p/' + pId + '/terms/relation/add/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                },
                body: JSON.stringify(relation),
            })
                .then(checkError)
                .then(res => {
                    onShowSuccessMessage();
                    if (termRelated[0].vedette && myAppState.form.vedette) {
                        updateState(res.id, termRelated[0].vedette, myAppState.form.vedette)
                    }
                    else if (termRelated[0].vedette) {
                        updateState(res.id, termRelated[0].vedette)
                    }
                    else if (myAppState.form.vedette) {
                        updateState(res.id, myAppState.form.vedette)
                    }
                })
                .catch(() => {
                    onShowErrorMessage();
                })
        }
    }

    async function toggleAddRelationPopup() {
        const { data } = await axios.get(`/p/${pId}/terms/${term}`)
        dispatch(appStateAction({ ...myAppState, addRelationIsOpen: false, refresh: true, form: data.data }))
    }

    async function updateState(relationId, id, idRelated) {
        let res2
        const res = await axios.get(`/p/${pId}/sc/item/${id}`)
        if (idRelated) {
            res2 = await axios.get(`/p/${pId}/sc/item/${idRelated}`)
            if (res.data.data.hypernyms && res2.data.data.hypernyms) dispatch(treeStateAction({ updatedSc: res.data.data.hypernyms.concat(res2.data.data.hypernyms), relationId }))
            else if (res.data.data.hypernyms) dispatch(treeStateAction({ updatedSc: res.data.data.hypernyms.concat(0), relationId }))
            else if (res2.data.data.hypernyms) dispatch(treeStateAction({ updatedSc: res2.data.data.hypernyms.concat(0), relationId }))
            else dispatch(treeStateAction({ updatedSc: [0], relationId }))
        }

        else if (res.data.data.hypernyms) dispatch(treeStateAction({ updatedSc: res.data.data.hypernyms, relationId }))
        else dispatch(treeStateAction({ updatedSc: [0], relationId }))
    }

    return (
        <div className=''>
            <Alert className="mx-5" variant="success" show={showSuccess} >
                Relation ajouté avec succès
            </Alert>
            <Alert className="mx-5" variant="danger" show={showError} >
                Une erreur s'est produite lors de la création de la relation !
            </Alert>
            <Form className="mt-3 mx-5" noValidate validated={validated} >
                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Terme
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control defaultValue={myAppState.form.reference} disabled />
                        {/* <Form.Control
                            required
                            disabled
                            as='select'
                            value={term}
                            onChange={(e) => setTerm(e.target.value)}
                        >
                            <option value=''>Select...</option>
                            {(myAppState.forms !== undefined) && myAppState.forms.map((form => (
                                <option value={form.id}>{form.reference}</option>
                            )))}
                        </Form.Control> */}
                        <Form.Control.Feedback type="invalid">
                            Veuillez renseigner ce champ.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Terme lié <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            className={checked && (termRelated == undefined || termRelated[0] == undefined) ? "is-invalid" : ""}
                            isInvalid={checked && (termRelated == undefined || termRelated[0] == undefined)}
                            id="search-related-term"
                            onChange={setTermRelated}
                            labelKey="reference"
                            emptyLabel={!(query !== undefined && query.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(query !== undefined && query.length !== 0) ? terms : []}
                            onInputChange={handleInputChange}
                            placeholder="Sélectionnez un terme..."
                            selected={termRelated || []}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(query && query.length > 0) ? "Veuillez renseigner une valeur existante." : "Veuillez renseigner ce champ."}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Nom de la relation <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control
                            className={checked && (relationName == null || relationName == '') ? "is-invalid" : ""}
                            isInvalid={checked && (relationName == null || relationName == '')}
                            required
                            as='select'
                            value={relationName}
                            onChange={(e) => setRelationName(e.target.value)}
                        >
                            <option value=''>Select...</option>
                            <option value="VARIATE">Variation</option>
                            <option value="ACRONYM">Acronyme</option>
                            <option value="TRANSLT">Traduction</option>
                            <option value="SYNONYM">Synonyme</option>
                            <option value="QUA-SYN">Quasi-Synonyme</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Veuillez renseigner ce champ.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <div className="text-center"><Button type="submit" onClick={formHandleSubmit}>Ajouter</Button></div>

            </Form>
        </div>
    )
}

export default AddTermRelation