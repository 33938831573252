import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from '@material-ui/core/';
import { useState, useEffect, useRef } from 'react';

const useStyles = makeStyles(
    theme => ({
        root: {
            // padding: '30px 24px 16px 24px',
            // margin: '10px 4px 6px 4px',

            // fontFamily: 'Roboto',
            // width: '200px'
        },
        title: {
            marginTop: '7px !important',

            marginLeft: '7px !important',
            marginRight: '24px !important',
            fontSize: '40px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            fontWeight: '1000 !important',
        },

        formGroup: {
            marginTop: '8px',
            marginLeft: "20px"
        },
        // formControl: {},
        // checkbox: {
        //     padding: '0px',
        //     width: '32px',
        //     height: '32px',
        // },
        // checkboxRoot: {},
        // checked: {},
        // label: {
        //     fontSize: '15px',
        //     marginLeft: '8px',
        //     color: theme.palette.text.primary,
        // },
    }),
    { name: 'MUIDataTableViewCol' },
);

const TableViewCol = ({ columns, options, components = {}, onColumnUpdate, updateColumns }) => {
    const classes = useStyles();
    const textLabels = options.textLabels.viewColumns;
    const CheckboxComponent = components.Checkbox || Checkbox;

    const handleColChange = index => {
        onColumnUpdate(index);
    };

    const firstRun = useRef(true);
    const [showAll, setShowAll] = useState(true)

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }
        let newColumns = columns.map(col => {
            let newCol = Object.assign({}, col);
            newCol.display = showAll.toString();
            return newCol;
        });
        updateColumns(newColumns);

    }, [showAll])

    const handleColsChange = () => {
        setShowAll(showAll => !showAll)
    }


    return (
        <FormControl component={'fieldset'} className={classes.root} aria-label={textLabels.titleAria}>
            <Typography variant="caption" className={classes.title}>
                {textLabels.title}
            </Typography>
            <FormGroup className={classes.formGroup}>

                <CheckboxComponent
                    color="primary"
                    className={classes.checkbox}
                    classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                    }}
                    onChange={handleColsChange}
                    checked={showAll}
                    value=""
                />
                {columns.map((column, index) => {
                    return (
                        column.display !== 'excluded' &&
                        column.viewColumns !== false && (
                            <FormControlLabel
                                key={index}
                                classes={{
                                    root: classes.formControl,
                                    label: classes.label,
                                }}
                                control={
                                    <CheckboxComponent
                                        color="primary"
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checkboxRoot,
                                            checked: classes.checked,
                                        }}
                                        onChange={() => handleColChange(index)}
                                        checked={column.display === 'true'}
                                        value={column.name}
                                    />
                                }
                                label={column.label}
                            />
                        )
                    );
                })}
            </FormGroup>
        </FormControl>
    );
};

TableViewCol.propTypes = {
    /** Columns used to describe table */
    columns: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger View column update */
    onColumnUpdate: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
};

export default TableViewCol;