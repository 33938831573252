import React, { useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import Loader from './Loader'
import NTreeItem from "./NTreeItem"
import { useDispatch, useSelector } from 'react-redux'
import { semanticRootAction } from './../actions/semanticRootActions';
import { TablePagination } from '@material-ui/core'
import { Droppable } from 'react-beautiful-dnd'

function NTree({ id, related, associated, hyponyms, keyword, childParentSemanticCallback, root }) {
    const dispatch = useDispatch()
    const myAppState = useSelector(state => state.semanticRoot)

    const [state, setState] = useState()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    const { scDropDest, scDropSrc, item, updatedSc, relationId } = useSelector(state => state.treeState)
    const [refresh, setRefresh] = useState(false)


    if (scDropDest != undefined && ((scDropDest == id) || (!id && scDropDest == 0)) && (!refresh || `${scDropDest}${scDropSrc}${item}` != refresh)) {
        setRefresh(`${scDropDest}${scDropSrc}${item}`)
    }

    if (scDropSrc != undefined && ((scDropSrc == id) || (!id && scDropSrc == 0)) && (!refresh || `${scDropDest}${scDropSrc}${item}` != refresh)) {
        setRefresh(`${scDropDest}${scDropSrc}${item}`)
    }

    if (updatedSc && (updatedSc.includes(id)) && (!refresh || `${updatedSc}${relationId}` != refresh)) {
        setRefresh(`${updatedSc}${relationId}`)
    } else if (updatedSc && updatedSc.includes(0) && !id && (!refresh || `${updatedSc}${relationId}` != refresh)) {
        setRefresh(`${updatedSc}${relationId}`)
    }


    useEffect(() => {
        if (!id && !hyponyms && (scDropDest != 0) && (scDropSrc != 0)) setLoading(true)
        async function fetchSC() {
            await axios.get((!id) ? `/p/${pId}/sc/root?offset=${page * 100}&limit=100` : `/p/${pId}/sc/item/${id}`)
                .then(res => {
                    if (!id) {
                        setState(res.data.data)
                        setCount(res.data.count)
                        dispatch(semanticRootAction({ ...myAppState, semanticRoot: res.data.data }));
                        setLoading(false)
                    }
                    else setState(res.data.data.hyponyms)

                })
                .catch(
                    function (error) {
                        setLoading(false)
                        return Promise.reject(error)
                    }
                )
        }
        if (!hyponyms) fetchSC()
    }, [hyponyms, refresh, page])

    const getChildSCValue = (value) => {
        childParentSemanticCallback(value)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div className='ml-4'>
            {(!id) && !hyponyms && root && <><TablePagination
                component="div"
                count={count}
                page={page}
                rowsPerPage={100}
                rowsPerPageOptions={[]}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} sur ${count}`; }}
            />
                <span className="mr-5 pl-0">Racine</span>
                <Droppable droppableId={`{"itemId": ${0}, "type": "racine" }`} >
                    {(provided, snapshot) => (
                        <div className={`ml-3 droppable-box-mini ${snapshot.isDraggingOver ? "dragging" : ""}`} style={{ borderBottom: snapshot.isDraggingOver ? '1px solid rgb(32, 82, 12)' : '' }} {...provided.droppableProps} ref={provided.innerRef} id="box-mini"></div>
                    )}
                </Droppable>
            </>}

            {
                (!hyponyms && !keyword) &&
                (loading ?
                    <div className="mt-5"><Loader /></div> : (!id) ?
                        (myAppState.semanticRoot != undefined) && myAppState.semanticRoot.map(item =>
                            <NTreeItem childParentSemanticCallback={getChildSCValue} item={item} related={related} associated={associated} />
                        ) : (state != undefined) && state.map(item =>
                            <>
                                {/* {(scDropDest == id) && "to update !"} */}
                                <NTreeItem childParentSemanticCallback={getChildSCValue} item={item} related={related} associated={associated} />
                            </>

                        ))
            }
            {
                (hyponyms) && hyponyms.map(item =>
                    <NTreeItem childParentSemanticCallback={getChildSCValue} item={item} related={related} associated={associated} hyponymsItem={true} keyword={keyword} />)
            }
        </div>
        // <div className='ml-4'>
        //     {(!hyponyms) ? "" : hyponyms.map(item =>
        //         <NTreeItem item={item} related={related} associated={associated} hyponymsItem={true} keyword={keyword} />)
        //     }
        // </div>

    )
}
export default NTree