import React, { useEffect, useState } from 'react'
import NTree from "../NTree"
import axios from 'axios';
import { Checkbox, CircularProgress } from '@material-ui/core'
import { getCookie } from './../../utils/utils';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useDispatch, useSelector } from 'react-redux'
import { isObjectEmpty } from '../../utils/utils'
import { semanticRootAction } from '../../actions/semanticRootActions';
import AddSC from '../AddSC';
import PopUp from '../PopUp';
import { TablePagination } from '@material-ui/core'
import { Droppable } from 'react-beautiful-dnd';


function SidebarPoc() {
    const [showAssociated, setShowAssociated] = useState(false);
    const [showRelated, setshowRelated] = useState(false);

    const [terms, setTerms] = useState([])
    const [query, setQuery] = useState()
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const csrftoken = getCookie('csrftoken');

    const [hyponyms, setHyponyms] = useState();
    const [sc, setSC] = useState()
    const dispatch = useDispatch()
    const [semantic, setSemantic] = useState();
    const [smShow, setSmShow] = useState(false);
    const [message, setMessage] = useState();
    const [addSCIsOpen, setAddSCIsOpen] = useState(false);

    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined
    const pRole = profileInfo ? projectRole.role : undefined

    useEffect(() => {
        async function fetchTerms(limit = 5, offset = 0) {
            await axios.post(`/p/${pId}/terms/?limit=${limit}&offset=${offset}`, query, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
                .then(res => {
                    setTerms(res.data.data)
                    setLoading(true)
                })
                .catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                )
        }
        setLoading(false)
        if (query !== undefined && query.length !== 0) fetchTerms()
    }, [query])

    const sCHandleInputChange = query => {
        setTerms([])
        setQuery({
            'surface_regex': query ? `(^${query})` : "",
            'sort': (["reference"]),
            "in_semantic": true,
        });
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchSc(newPage)
    };

    async function fetchSc(newPage) {
        if (sc != undefined) {
            const { data } = await axios.get((newPage == null) ? `/p/${pId}/sc/path/${sc[0].vedette}?offset=${page * 100}&limit=100` : `/p/${pId}/sc/path/${sc[0].vedette}?offset=${newPage * 100}&limit=100`)
            setHyponyms(data.data)
            if (!newPage) setCount(data.count)
            setKeyword(sc[0].reference)
        }
        setIsLoading(false)
    }

    const showAssociatedHandleChange = (event) => {
        setShowAssociated(event.target.checked);
    };
    const showRelatedHandleChange = (event) => {
        setshowRelated(event.target.checked);
    };

    async function fetchSCs() {
        await axios.get(`/p/${pId}/sc/root?offset=0&limit=100`)
            .then(res => {
                dispatch(semanticRootAction({ semanticRoot: res.data.data }))
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }

    async function handleDelete() {
        if (semantic == undefined) {
            if (sc != undefined && !isObjectEmpty(sc[0])) {
                await axios.get(`/p/${pId}/sc/item/${sc[0].vedette}/`)
                    .then(async (res) => {
                        setSemantic(res.data.data)
                        if (res.data.data.has_hyponyms == true) {
                            setMessage('Vous ne pouvez pas supprimer cette classe car elle contient des hyponymes')
                            setSmShow(true)
                        }
                        else {
                            await fetch(`/p/${pId}/sc/delete/${res.data.data.id}/`, {
                                method: 'DELETE',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'X-CSRFToken': csrftoken
                                },
                            }).then(res => {
                                fetchSCs();
                                setSC([])
                                setSemantic(undefined)
                            })
                                .catch(
                                    function (error) {
                                        return Promise.reject(error)
                                    }
                                )
                        }
                    })
            }
            else {
                setMessage('Veuillez sélectionner une classe sémantique')
                setSmShow(true)
            }
        } else {
            if (semantic.has_hyponyms == true) {
                setMessage('Vous ne pouvez pas supprimer cette classe car elle contient des hyponymes')
                setSmShow(true)
            }
            else {
                await fetch(`/p/${pId}/sc/delete/${semantic.id}/`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken
                    },
                }).then(res => {
                    fetchSCs();
                    setSC([])
                    setSemantic(undefined)
                })
                    .catch(
                        function (error) {
                            return Promise.reject(error)
                        }
                    )
            }
        }
    }

    async function handleEdit() {
        if (semantic == undefined) {
            if (sc != undefined && !isObjectEmpty(sc[0])) {
                await axios.get(`/p/${pId}/sc/item/${sc[0].vedette}/`)
                    .then(async (res) => {
                        setSemantic(res.data.data)
                        setAddSCIsOpen(true)
                    })
            }
            else {
                setMessage('Veuillez sélectionner une classe sémantique')
                setSmShow(true)
            }
        } else {
            setAddSCIsOpen(true)
        }
    }

    const getChildSCValue = (value) => {
        setSC([value.termVedette])
        setSemantic(value)
    };

    return (
        <div className='ml-1'>
            {addSCIsOpen && <PopUp
                content={<>
                    <AddSC semantic={semantic} setAddSCIsClose={setAddSCIsOpen} />
                </>}
                handleClose={() => setAddSCIsOpen(addSCIsOpen => !addSCIsOpen)}
            />}
            <Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                dialogClassName="w-75"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
            </Modal>
            <span className="mr-2">
                <Checkbox
                    checked={showAssociated}
                    onChange={showAssociatedHandleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="default"
                    size="small"
                /> Classes Associées
            </span>

            <span className="mr-2">
                <Checkbox
                    checked={showRelated}
                    onChange={showRelatedHandleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="default"
                    size="small"
                /> Termes liés
            </span>

            <Form.Group as={Row} className="my-1" controlId="">
                <Col sm="6" xs="12">
                    <Typeahead
                        className={(sc && !isObjectEmpty(sc)) ? `typeaheadSelected` : ""}
                        id="search-class"
                        onChange={(data) => { setSemantic(); setSC(data); setTerms([]) }}
                        labelKey="reference"
                        emptyLabel={!(query !== undefined && query.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                        options={(query !== undefined && query.length !== 0) ? terms : []}
                        onInputChange={sCHandleInputChange}
                        placeholder="Sélectionnez une classe..."
                        selected={sc || []}
                    />
                </Col>
                <Col sm="4" xs="6" className=''>
                    <Row>
                        <Button size="sm" onClick={() => { setIsLoading(true); fetchSc() }}><i className="fas fa-search"></i></Button>
                        {hyponyms && <Button title="Actualiser l'arbre des classes" size="sm" className="ml-2" variant='info' onClick={() => { setHyponyms(undefined); setKeyword(undefined); setSC(null) }}><i className="fas fa-redo" /></Button>}
                        {isLoading && <CircularProgress className="mt-1 mr-3" size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        {(pRole > 0) && <div>
                            <Button title="Modifier la classe" size="sm" className="ml-1" variant='warning' onClick={handleEdit}><EditIcon /></Button>
                            <Button title="Supprimer la classe" size="sm" className="ml-1" variant='danger' onClick={handleDelete}><DeleteIcon /></Button>
                        </div>}
                    </Row>
                </Col>

            </Form.Group>
            {/* <span className="ml-5">Racine</span>
            <Droppable droppableId={`{"itemId": ${0}, "type": "racine" }`} >
                {(provided, snapshot) => (
                    <div className={`ml-3 droppable-box-mini ${snapshot.isDraggingOver ? "dragging" : ""}`} style={{ borderBottom: snapshot.isDraggingOver ? '1px solid rgb(32, 82, 12)' : '' }} {...provided.droppableProps} ref={provided.innerRef} id="box-mini"></div>
                )}
            </Droppable> */}
            {
                hyponyms && <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={100}
                    rowsPerPageOptions={[]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} sur ${count}`; }}
                />
            }


            {
                (!hyponyms) ?
                    <NTree childParentSemanticCallback={getChildSCValue} related={showRelated} associated={showAssociated} root={true} /> :
                    <NTree childParentSemanticCallback={getChildSCValue} related={showRelated} associated={showAssociated} hyponyms={hyponyms} keyword={keyword} root={true} />
            }
        </div >
    )
}

export default SidebarPoc
