import {
    SEMANTIC_ROOT_SUCCESS,
} from '../constants/semanticRootConstants'

export const semanticRootAction = (data) => async (dispatch) => {
    dispatch({
        type: SEMANTIC_ROOT_SUCCESS,
        payload: data,
    })
}
