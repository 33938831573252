import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logOut } from './../actions/profileActions';


function LogoutScreen() {
    const dispatch = useDispatch()
    const [loggedout, setLoggedout] = useState(false)

    useEffect(() => {
        async function logout() {
            await axios.get('/u/logout/')
                .then(res => {
                    dispatch(logOut())
                    setLoggedout(true)
                })
                .catch(
                    function (error) {
                        console.log(error)
                    }
                )
        }
        logout()
    }, [])


    return (
        <div className='mt-5 text-center'>
            {!!loggedout && "Vous êtes déconnecté !"}
        </div>
    )
}

export default LogoutScreen