import React, { useEffect, useState, useContext } from 'react'
import { Form, Col, Row, Button, Alert } from 'react-bootstrap'
import axios from 'axios'
import languages from '../config/languages.json'

import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from '../utils/utils';
import { appStateAction } from '../actions/appStateActions';

function AddTerm() {
    const myAppState = useSelector(state => state.appState)
    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    let termId = ''
    let surfaceFormDup = ''
    let lemmaDup = ''
    let posTaggingDup = ''
    let langDup = 0
    let formHeadDup = ''
    let formExpDup = ''
    let expTypeDup = ''


    if (myAppState.duplicateFormIsOpen === true) {
        termId = myAppState.form.id
        surfaceFormDup = myAppState.form.reference
        lemmaDup = myAppState.form.form.lemma
        posTaggingDup = myAppState.form.form.posTagging
        langDup = myAppState.form.form.language
        formHeadDup = (myAppState.form.nth_head === null || myAppState.form.nth_head === undefined) ? '' : [myAppState.form.nth_head]
        formExpDup = (myAppState.form.nth_exp === null || myAppState.form.nth_exp === undefined) ? '' : [myAppState.form.nth_exp]
        expTypeDup = myAppState.form["expansion type"]
    }

    const [surfaceForm, setSurfaceForm] = useState(surfaceFormDup)
    const [lemma, setLemma] = useState(lemmaDup)
    const [posTagging, setPosTagging] = useState(posTaggingDup)
    const [lang, setLang] = useState(langDup)
    const [formHead, setFormHead] = useState(formHeadDup)
    const [formExp, setFormExp] = useState(formExpDup)
    const [expType, setExpType] = useState(expTypeDup)

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);

    const [terms, setTerms] = useState([])

    const [query, setQuery] = useState()

    const handleInputChange = query => {
        setTerms([])
        setQuery(query);
    };

    const [loading, setLoading] = useState(false)

    var csrftoken = getCookie('csrftoken');

    const dispatch = useDispatch()



    useEffect(() => {
        async function fetchTerms(limit = 10, offset = 0) {
            await axios.post(`/p/${pId}/terms/?limit=${limit}&offset=${offset}`, {
                'surface_regex': query ? `(^${query})` : "",
                'sort': (["reference"])
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
                .then(res => {
                    setTerms(res.data.data)
                    setLoading(true)
                })
                .catch(
                    function (error) {
                        return Promise.reject(error)
                    }
                )
        }
        setLoading(false)
        if (query !== undefined && query.length !== 0) fetchTerms()
    }, [query])




    const onAddShowSuccessMessage = (res) => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            toggleAddTermPopup(res.id)
        }, 500)
    };

    const onDuplicateShowSuccessMessage = (res) => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
            toggleDuplicateTermPopup(res.id)
        }, 500)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    const surfaceFormHandleChange = (event) => {
        setSurfaceForm(event.target.value)
    }

    const lemmaHandleChange = (event) => {
        setLemma(event.target.value)
    }

    const posTaggingHandleChange = (event) => {
        setPosTagging(event.target.value)
    }


    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }

    const formHandleSubmit = event => {
        event.preventDefault();
        const form = {
            reference: surfaceForm,
            headTerm: (formHead[0] !== undefined) ? formHead[0].id : null,
            expansion: (formExp[0] !== undefined) ? formExp[0].id : null,
            "expansion type": expType,
            project: pId,
            form: {
                language: (lang === 0) ? "unk" : lang,
                lemma: lemma,
                posTagging: posTagging,
            },
        };


        if (surfaceForm !== undefined && surfaceForm !== "") {
            if (myAppState.AddTermIsOpen === true) {
                fetch('/p/' + pId + '/terms/add/', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken
                    },
                    body: JSON.stringify(form),
                })
                    .then(checkError)
                    .then(res => {
                        onAddShowSuccessMessage(res);
                    })
                    .catch(() => {
                        onShowErrorMessage();
                    })

            }
            if (myAppState.duplicateFormIsOpen === true) {
                fetch(`/p/${pId}/terms/update/${termId}/`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken
                    },
                    body: JSON.stringify(form),
                })
                    .then(checkError)
                    .then(res => {
                        onDuplicateShowSuccessMessage(res);
                    })
                    .catch(() => {
                        onShowErrorMessage();
                    })

            }
        }
        setValidated(true);
    }

    async function toggleDuplicateTermPopup(id) {
        const { data } = await axios.get(`/p/${pId}/terms/${id}`)
        dispatch(appStateAction({
            ...myAppState, duplicateFormIsOpen: false, refresh: true,
            form: data.data
        }))
    }

    async function toggleAddTermPopup(id) {
        const { data } = await axios.get(`/p/${pId}/terms/${id}`)
        dispatch(appStateAction({ ...myAppState, AddTermIsOpen: false, refresh: true, form: data.data }))
    }

    return (
        <div>
            <Alert className="mx-5" variant="success" show={showSuccess} >
                {myAppState.duplicateFormIsOpen ? "Terme modifié avec succès" : "Terme ajouté avec succès"}
            </Alert>
            <Alert className="mx-5" variant="danger" show={showError} >
                {myAppState.duplicateFormIsOpen ? "Une erreur s'est produite lors de la modification du terme" : "Une erreur s'est produite lors de la création du terme"}
            </Alert>
            {myAppState.duplicateFormIsOpen && <p className='mx-2 center'>Vous êtes sur le point de modifier ce terme, cela signifie qu'une nouvelle forme va être créée pour devenir la forme canonique du terme et que la forme canonique actuelle va devenir une forme remplacée.</p>}
            <Form className="mt-3 mx-5" noValidate validated={validated} >
                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Forme de surface <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control required defaultValue={surfaceForm} onChange={surfaceFormHandleChange} />
                        <Form.Control.Feedback type="invalid">
                            Veuillez renseigner ce champ.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Langue
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control
                            as='select'
                            value={lang}
                            onChange={(e) => setLang(e.target.value)}
                        >
                            <option value=''>Select...</option>
                            {languages.map((language => (
                                <option value={Object.keys(language)}>{Object.values(language)}</option>
                            )))}
                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Lemme
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control defaultValue={lemma} onChange={lemmaHandleChange} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        POS Tagging
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control defaultValue={posTagging} onChange={posTaggingHandleChange} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Tête
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            id="search-head"
                            onChange={(data) => { setFormHead(data); setTerms([]) }}
                            labelKey="reference"
                            emptyLabel={!(query !== undefined && query.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(query !== undefined && query.length !== 0) ? terms : []}
                            onInputChange={handleInputChange}
                            placeholder="Sélectionnez un terme..."
                            selected={formHead || []}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Expansion
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Typeahead
                            id="search-expansion"
                            onChange={(data) => { setFormExp(data); setTerms([]) }}
                            labelKey="reference"
                            emptyLabel={!(query !== undefined && query.length !== 0) ? "Commencer à saisir..." : !loading ? "Recherche..." : "Aucune correspondance trouvée"}
                            options={(query !== undefined && query.length !== 0) ? terms : []}
                            onInputChange={handleInputChange}
                            placeholder="Sélectionnez un terme..."
                            selected={formExp || []}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="4" xs="12">
                        Position
                    </Form.Label>
                    <Col sm="8" xs="12">
                        <Form.Control
                            as='select'
                            value={expType}
                            onChange={(e) => setExpType(e.target.value)}
                        >
                            <option value=''>Select...</option>
                            <option value='EXP-B'>avant</option>
                            <option value='EXP-F'>après</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <div className="text-center"><Button type="submit" onClick={formHandleSubmit}>{myAppState.duplicateFormIsOpen ? "Modifier le terme" : "Ajouter le terme"}</Button></div>

            </Form>
        </div>
    )
}

export default AddTerm