import React, { useContext } from 'react'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import Highlighter from "react-highlight-words";
import { useSelector } from 'react-redux'
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: "100%"
    }
  }),
  { name: "myStyles" }
)

const myTheme = createTheme({
  overrides: {
    MUIDataTable: {
      tableRoot: {
        minHeight: '232px !important',
        background: "#CBF0F0"
      },
      paper: {
        background: "#E6EDEC"
      },
      responsiveScroll: {
        minHeight: '150px !important',

        maxHeight: '250px !important'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: "#e6edec !important",
      }
    }
    //   MUIDataTableToolbar: { root: { display: 'none' } }
  }
});






const options = {
  filterType: "checkbox",
  selectableRows: false, // <===== will turn off checkboxes in rows
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  rowsPerPageOptions: [2, 5, 10],
  tableBodyHeight: "calc(100% - 41px)",
  // tableBodyHeight: "calc(100% - 84px)",
  textLabels: {
    body: {
      noMatch: 'Désolé, il n\'y a pas de données correspondantes à afficher'
    },
  }
}

function TermInContextTable() {
  const classes = useStyles();

  const myAppState = useSelector(state => state.appState)
  const form = (myAppState === undefined) ? 0 : (myAppState.detailsTab === true) ? myAppState.form : 0
  const docs = (form !== 0) ? form.documents.docs : []


  const columns = [
    // {
    //   name: "nomFich",
    //   label: "Nom Fichier",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "nombrePhr",
    //   label: "Nombre de phrases",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "content",
      label: "Contexte",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (content, meta) => (
          < Highlighter
            highlightClassName="highlightClass"
            searchWords={[myAppState.form.reference]}
            autoEscape={true}
            textToHighlight={content}
          />
        ),
      },
    },
  ];


  return (
    <div style={{ flex: 1, height: "100%" }}>
      {(myAppState !== undefined && myAppState.contextTab) &&
        <MuiThemeProvider theme={myTheme}>
          <MUIDataTable
            className={classes.root}
            data={docs}
            columns={columns}
            options={options} />
        </MuiThemeProvider>}
    </div>
  );
}

export default TermInContextTable


