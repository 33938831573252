import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { getCookie, isObjectEmpty } from "../utils/utils";
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../actions/profileActions';

const RedirectComponent = () => {
    const csrftoken = getCookie('csrftoken')

    async function fetchRedirectLink() {
        await axios.post('/u/login/', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            }
        })
            .then(res => {
                window.location.replace(res.data.redirect)
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }
    fetchRedirectLink()

    return <div className='mt-5 text-center'>Redirection vers la page de connexion </div>
}

const RequireAuth = ({ allowedRoles, projectLessRoute }) => {
    let role = null
    const dispatch = useDispatch()

    const profileInfo = useSelector(state => state.profile)
    const { profile, authenticated, projectRole, loaded } = profileInfo

    if (projectRole && projectRole.role != undefined) role = projectRole.role



    useEffect(() => {
        if (isObjectEmpty(profile))
            dispatch(getProfile())
    }, [])

    return (
        !!loaded && (!authenticated
            ? <RedirectComponent />
            : (role == null)
                ? (projectLessRoute ? <Outlet /> : <Navigate to="/projects" />)
                : allowedRoles && allowedRoles.includes(role)
                    ? <Outlet />
                    : <Navigate to="/unauthorized" />)
    );
}

export default RequireAuth;