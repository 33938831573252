import {
    TREE_STATE_SUCCESS
} from '../constants/treeStateConstants'

export const treeStateReducer = (state = {}, action) => {
    switch (action.type) {
        case TREE_STATE_SUCCESS:
            return { ...action.payload }

        default:
            return state
    }

}
