import React, { useEffect } from 'react'
import * as FlexLayout from "flexlayout-react";
import "../../node_modules/flexlayout-react/style/gray.css";
import TermSearch from '../components/TermSearch';
import SidebarPoc from '../components/sidebar/SidebarPoc';
import EditTerm from '../components/EditTerm';
import TermInContextTable from '../components/TermInContextTable';
import { DragDropContext } from 'react-beautiful-dnd';
import { getCookie } from '../utils/utils';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { semanticRootAction } from '../actions/semanticRootActions';
import { treeStateAction } from '../actions/treeStateActions';

function ProjectScreen() {
    const dispatch = useDispatch()
    const csrftoken = getCookie('csrftoken')

    const factory = (node) => {
        var component = node.getComponent();

        if (component === "searchTermTable") {
            return <TermSearch />;
        }

        else if (component === "sidebarPoc") {
            return <SidebarPoc />;
        }

        else if (component === "editTerm") {
            return <EditTerm />;
        }

        else if (component === "tableDetails") {
            return <TermInContextTable />;
        }
    }

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined

    const json = {
        global: {},
        borders: [],
        layout: {
            type: "row",
            weight: 100,
            children: [
                {
                    type: "row",
                    weight: 30,
                    selected: 0,
                    children: [
                        {
                            type: "tabset",
                            weight: 45,
                            selected: 0,
                            enableDeleteWhenEmpty: false,
                            children: [
                                {
                                    type: "tab",
                                    name: "Structurer les classes sémantiques",
                                    component: "sidebarPoc",
                                    enableClose: false
                                }
                            ]
                        },
                        {
                            type: "tabset",
                            weight: 55,
                            selected: 0,
                            children: [
                                {
                                    type: "tab",
                                    name: "Voir le terme",
                                    component: "editTerm",
                                    enableClose: false
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "row",
                    weight: 70,
                    selected: 0,
                    children: [
                        {
                            type: "tabset",
                            id: "rightTabs",
                            weight: 65,
                            selected: 0,
                            enableDeleteWhenEmpty: false,
                            children: [
                                {
                                    type: "tab",
                                    name: "Recherche de termes",
                                    component: "searchTermTable"
                                }
                            ]
                        },
                        {
                            type: "tabset",
                            weight: 35,
                            selected: 0,
                            children: [
                                {
                                    type: "tab",
                                    name: "Voir le terme dans son contexte",
                                    component: "tableDetails",
                                    enableClose: false
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    };



    async function fetchSCs() {
        await axios.get(`/p/${pId}/sc/root?offset=0&limit=100`)
            .then(res => {
                dispatch(semanticRootAction({ semanticRoot: res.data.data }))
            })
            .catch(
                function (error) {
                    return Promise.reject(error)
                }
            )
    }

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }


    async function handleOnDragEnd(result) {
        if (!result.destination) return;

        const termDrag = result.source.droppableId
        const scsrc = JSON.parse(result.draggableId).id
        const droptype = JSON.parse(result.destination.droppableId).type
        const scdest = JSON.parse(result.destination.droppableId).itemId
        const hyperPrev = JSON.parse(result.draggableId).hyper

        if (termDrag == "term") {
            let sC
            if (droptype == "subClasse") {
                sC = {
                    termVedette: (scsrc !== undefined) ? parseInt(scsrc) : null,
                    hypernymSC: [scdest],
                    project: pId
                };
            }
            if (droptype == "racine") {
                sC = {
                    termVedette: (scsrc !== undefined) ? parseInt(scsrc) : null,
                    project: pId
                };
            }
            // if (droptype == "1") {
            //     await axios.get(`/p/${pId}/sc/${scdest}`)
            //         .then(async res => {
            //             sC = {
            //                 termVedette: (scsrc !== undefined) ? parseInt(scsrc) : null,
            //                 hypernymSC: [res.data.data.hypernymSC],
            //                 project: pId
            //             }
            //         })
            //         .catch(
            //             function (error) {
            //                 return Promise.reject(error)
            //             }
            //         )
            // }

            if (scsrc !== undefined) {
                fetch('/p/' + pId + '/sc/add/', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken
                    },
                    body: JSON.stringify(sC),
                })
                    .then(res => {
                        fetchSCs();
                    })
                    .catch(
                        function (error) {
                            return Promise.reject(error)
                        }
                    )
            }
        }
        else {
            if (droptype == "subClasse") {
                await axios.post(`/p/${pId}/sc/move/${scsrc}/`,
                    (hyperPrev != null) ? { 'source': hyperPrev, 'dest': scdest } : { 'dest': scdest }
                    , {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrftoken
                        }
                    })
                    .then(res => {
                        console.log(res);

                    })
                    .catch(
                        function (error) {
                            return Promise.reject(error)
                        }
                    )
            }
            if (droptype == "racine" && (hyperPrev != null)) {
                await axios.post(`/p/${pId}/sc/move/${scsrc}/`,
                    { 'source': hyperPrev }
                    , {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrftoken
                        }
                    })
                    .then(res => {
                        console.log(res);
                    })
                    .catch(
                        function (error) {
                            return Promise.reject(error)
                        }
                    )
            }

            // if (droptype == "1") {
            //     await axios.get(`/p/${pId}/sc/${scdest}`)
            //         .then(async res => (
            //             await axios.get(`/p/${pId}/sc/${scsrc}`)
            //                 .then(res2 => {
            //                     updateSemantic(res2.data.data.id, res2.data.data.termVedette.id, res.data.data.hypernymSC)
            //                 })
            //                 .catch(
            //                     function (error) {
            //                         return Promise.reject(error)
            //                     }
            //                 )
            //         ))
            //         .catch(
            //             function (error) {
            //                 return Promise.reject(error)
            //             }
            //         )
            // }
        }
        if (scdest != undefined && hyperPrev != undefined) {
            dispatch(treeStateAction({ scDropDest: scdest, scDropSrc: hyperPrev, item: scsrc }))
        }
        else if (scdest != undefined) {
            dispatch(treeStateAction({ scDropDest: scdest, scDropSrc: 0, item: scsrc }))
        }
        else if (hyperPrev != undefined) {
            dispatch(treeStateAction({ scDropSrc: scdest, scDropSrc: hyperPrev, item: scsrc }))
        }
    }


    return (
        <div>
            <div
                className='mt-5'
                style={{
                    position: "relative",
                    height: "calc(95vh)"
                }}
            >
                <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={() => {
                }}>
                    <FlexLayout.Layout model={FlexLayout.Model.fromJson(json)} factory={factory} />
                </DragDropContext>
            </div>
        </div>

    )
}

export default ProjectScreen
