import React, {
    useState,
} from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { getCookie } from '../utils/utils';


function FilterScreen() {
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const profileInfo = useSelector(state => state.profile)
    const { projectRole } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined


    const onShowSuccessMessage = () => {
        setShowSuccess(true)
        window.setTimeout(() => {
            setShowSuccess(false)
        }, 2000)
    };

    const onShowErrorMessage = () => {
        setShowError(true)
        window.setTimeout(() => {
            setShowError(false)
        }, 2000)
    };

    const csrftoken = getCookie('csrftoken');

    async function onClickButton() {
        setIsLoading(true)
        await fetch('/p/' + pId + '/filter/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        })
            .then(checkError)
            .then(data => {
                setIsLoading(false)
                onShowSuccessMessage()
                setMessage("Filtrage des termes en cours, veuillez vérifier la page des statuts des tâches")
            })

            .catch((res) => {
                setIsLoading(false)
                onShowErrorMessage();
                console.log(res.statusText);
            })

    }

    function checkError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }


    return (
        <div>

            <div className="container-element">
                <div className="container text-center mt-5">
                    <h4 className="mb-3">Filtre</h4>
                    <Alert className="mx-5" variant="success" show={showSuccess} >
                        Termes filtrés avec succès
                    </Alert>
                    <Alert className="mx-5" variant="danger" show={showError} >
                        Une erreur s'est produite lors de la filtration des termes
                    </Alert>
                    {isLoading && <div className="m-2"><Loader>Filtre des termes ...</Loader></div>}
                    <div className="text-center"><Button className="mt-2" onClick={onClickButton} >Filtrer</Button></div>
                    <p className="m-2">{message}</p>
                </div>
            </div>
        </div >
    )
}

export default FilterScreen
