import React, { useContext, useEffect, useState } from 'react'
import { Form, Col, Row, Collapse, Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Chip } from '@material-ui/core';
import { getCookie, isObjectEmpty } from '../utils/utils';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { appStateAction } from '../actions/appStateActions';
import axios from 'axios';
import { treeStateAction } from '../actions/treeStateActions';

const CHIP_MAX_WIDTH = 285;
const CHIP_ICON_WIDTH = 30;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(0.5)
    },
    chip: {
        maxWidth: CHIP_MAX_WIDTH
    }
}));

const EllipsisText = props => {
    const { children } = props;

    return (
        <div
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH
            }}
        >
            {children}
        </div>
    );
};



function EditTerm() {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(true);
    const [open1, setOpen1] = useState(false);
    const myAppState = useSelector(state => state.appState)
    const term = (myAppState === undefined) ? 0 : (myAppState.detailsTab === true) ? myAppState.form : 0

    const head = (term === 0) ? "" : (term.nth_head === null || term.nth_head === undefined) ? "" : term.nth_head.reference
    const exp = (term === 0) ? "" : (term.nth_exp === null || term.nth_exp === undefined) ? "" : term.nth_exp.reference

    const csrftoken = getCookie('csrftoken');
    const classes = useStyles();

    const profileInfo = useSelector(state => state.profile)
    const { projectRole, profile } = profileInfo
    const pId = profileInfo ? projectRole.projectId : undefined
    // const reviewTags = profileInfo ? projectRole.reviews : undefined

    const [reviewTags, setReviewTags] = useState()
    const [loading, setLoading] = useState(false)
    const [dblind, setDblind] = useState(true)

    useEffect(() => {
        setLoading(true)
        async function fetchReviewTags() {
            await axios.get(`/p/${pId}/`)
                .then(res => {
                    setReviewTags(res.data.data.reviews)
                    setDblind(res.data.data.dblind)
                    setLoading(false)
                })
                .catch(
                    function (error) {
                        setLoading(false)
                        return Promise.reject(error)
                    }
                )
        }
        fetchReviewTags()
    }, [])


    async function handleDelete(id) {
        await fetch(`/p/${pId}/terms/relation/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
        });
        updateState(term.id, id)

    }

    async function updateState(id, relationId) {
        const { data } = await axios.get(`/p/${pId}/terms/${id}`)
        dispatch(appStateAction({ ...myAppState, form: data.data, refresh: true }))

        if (data.data.vedette) {
            const res = await axios.get(`/p/${pId}/sc/item/${data.data.vedette}`)
            if (res.data.data.hypernyms) dispatch(treeStateAction({ updatedSc: res.data.data.hypernyms, relationId }))
            else dispatch(treeStateAction({ updatedSc: [0], relationId }))
        }

    }

    async function onReviewAddChange(e) {
        if (e.target.value != 0) {
            await axios.post(`/p/${pId}/terms/${term.id}/review/add/`, {
                "profile": profile.user_id,
                "tag": e.target.value
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
            const { data } = await axios.get(`/p/${pId}/terms/${term.id}`)
            dispatch(appStateAction({ ...myAppState, form: data.data }))
        }
    }

    async function onReviewUpdateChange(e) {
        if (e.target.value != 0) {
            await axios.post(`/p/${pId}/terms/${term.id}/review/update/${term.reviews.self.id}/`, {
                "term": term.id,
                "profile": profile.user_id,
                "tag": e.target.value
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken
                }
            })
            const { data } = await axios.get(`/p/${pId}/terms/${term.id}/`)
            dispatch(appStateAction({ ...myAppState, form: data.data }))
        }
    }

    return (
        <div>
            <Form className="mt-3 mx-5">
                <Row>
                    <Col md={6}>Forme de surface :</Col>
                    <Col md={6}>{(term !== 0) ? term.form ? term.form.surfaceForm : "" : ""}</Col>
                </Row>
                <Row>
                    <Col md={6}>Lemme :</Col>
                    <Col md={6}>{(term !== 0) ? term.form ? term.form.lemma : "" : ""}</Col>
                </Row>
                <Row>
                    <Col md={6}>POS Tagging :</Col>
                    <Col md={6}>{(term !== 0) ? term.form ? term.form.posTagging : "" : ""}</Col>
                </Row>
                <Row>
                    <Col md={6}>Formes remplacées :</Col>
                    <Col md={6}>{(term !== 0) ? term.replaced && term.replaced.map(item =>
                        <div className="m-1 container-item">
                            <Chip
                                label={item.surfaceForm}
                            />
                        </div>
                    ) : ""}</Col>
                </Row>
            </Form>
            <div className="no-borderRadiusImportant mx-3" style={{ border: '2px solid black', borderRadius: '5px!important' }}>

                <div
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="m-3"
                >
                    {(!open) ? <i className="fas fa-caret-right"></i> : <i className="fas fa-caret-down"></i>} Avis
                </div>
                <Collapse in={open}>
                    <div>
                        <Form className="mt-3 mx-5">
                            <Form.Group as={Row} className="mb-3" controlId="">
                                <Form.Label column sm="6" xs="12">
                                    Mon avis
                                </Form.Label>
                                <Col sm="6" xs="12">
                                    <Table striped bordered hover size="sm" style={{ width: "100px" }}>


                                        <tbody>
                                            {!isObjectEmpty(term) && ((term.reviews.self.tag) ? <tr>
                                                <td>{term.reviews.self.username}</td>
                                                <td >
                                                    <Form.Control
                                                        style={{ width: "75px" }}
                                                        size="sm"
                                                        as='select'
                                                        value={term.reviews.self.tag}
                                                        onChange={onReviewUpdateChange}
                                                    >
                                                        <option value={0}>Select...</option>
                                                        {!loading && reviewTags.map(reviewTag => {
                                                            return <option value={reviewTag.id}>{reviewTag.tag}</option>
                                                        })}
                                                    </Form.Control>
                                                </td>
                                            </tr> : <tr>
                                                <td>{profile.username}</td>
                                                <td >
                                                    <Form.Control
                                                        style={{ width: "75px" }}
                                                        size="sm"
                                                        as='select'
                                                        value={0}
                                                        onChange={onReviewAddChange}
                                                    >
                                                        <option value={0}>Select...</option>
                                                        {!loading && reviewTags.map(reviewTag => {
                                                            return <option value={reviewTag.id}>{reviewTag.tag}</option>
                                                        })}
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>

                                    </Table>
                                </Col>
                            </Form.Group>

                            {!dblind &&
                                <Form.Group as={Row} className="mb-3" controlId="">
                                    <Form.Label column sm="6" xs="12">
                                        L'avis des autres utilisateurs
                                    </Form.Label>
                                    <Col sm="6" xs="12">
                                        <Table responsive striped bordered hover size="sm" style={{ width: "100px" }}>
                                            {/* <thead>
                                            <tr>
                                                <th>Utilisateur</th>
                                                <th>Avis</th>
                                            </tr>
                                        </thead> */}
                                            <tbody>
                                                {!isObjectEmpty(term) && (term.reviews.set.length != 0) && term.reviews.set.map(review => {
                                                    return <tr key={review.id}>
                                                        <td>{review.username}</td>
                                                        <td>{review.tagname}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Form.Group>}
                        </Form>
                    </div>
                </Collapse>
            </div>
            <div className="no-borderRadiusImportant mx-3 mt-1" style={{ border: '2px solid black', borderRadius: '5px!important' }}>

                <div
                    onClick={() => setOpen1(!open1)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open1}
                    className="m-3"
                >
                    {(!open1) ? <i className="fas fa-caret-right"></i> : <i className="fas fa-caret-down"></i>} Autres propriétés
                </div>
                <Collapse in={open1}>
                    <div className='px-5 pb-2'>
                        <Row>
                            <Col md={6}>Tête :</Col>
                            <Col md={6}>{head}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Expansion :</Col>
                            <Col md={6}>{exp}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Langue :</Col>
                            <Col md={6}>{(term !== 0) ? term.form ? term.form.language : "" : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Analyse syntaxique :</Col>
                            <Col md={6}>{(term !== 0) ? term.form ? term.form.analysis : "" : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Autres termes liés :</Col>
                            <Col md={6} className={classes.root}>
                                {(term !== 0) ?
                                    (term.relations !== undefined) ? Object.entries(term.relations).map(relation =>
                                        <Tooltip title={relation[1]} className="mb-1">
                                            <Chip
                                                key={relation[0]}
                                                label={<EllipsisText>{relation[1]}</EllipsisText>}
                                                variant="outlined"
                                                onDelete={() => handleDelete(relation[0])}
                                                className={classes.chip}
                                            />
                                        </Tooltip>
                                    ) : ""
                                    : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Nb de documents :</Col>
                            <Col md={6}>{(term !== 0) ? term.documents.docs.length : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Nb de mots :</Col>
                            <Col md={6}>{(term !== 0) ? term.words : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Nb d'occurrences :</Col>
                            <Col md={6}>{(term !== 0) ? term.documents.occurences : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Productivité tête :</Col>
                            <Col md={6}>{(term !== 0) ? term.head_pdty : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Productivité expansion :</Col>
                            <Col md={6}>{(term !== 0) ? term.exp_pdty : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Pertinence :</Col>
                            <Col md={6}>{(term !== 0) ? term.form ? term.form.relevanceScore : "" : ""}</Col>
                        </Row>
                        <Row>
                            <Col md={6}>Producteur :</Col>
                            <Col md={6}>{(term !== 0) ? term.form ? term.form.source.label : "" : ""}</Col>
                        </Row>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default EditTerm
