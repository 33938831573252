import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader({ children }) {
    return (
        <div>
            <Spinner
                animation='border'
                role='status'
                style={{
                    height: '60px',
                    width: '60px',
                    margin: 'auto',
                    display: 'block'
                }}
            >
                <span className='sr-only'>Loading...</span>
            </Spinner>
            <p className="mt-2">{children}</p>
        </div>
    )
}

export default Loader
